import React, { useEffect, useState } from 'react'
import { Box, Pagination, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded, ModeEditRounded } from '@mui/icons-material'
import * as moment from 'moment'

import AddButton from '../custom/add-button'
import CircularBackdrop from '../custom/circular-backdrop'
import UpsertDialog from './upsert-dialog'
import DeleteDialog from '../custom/delete-dialog'
import LITERALS from '../../helpers/literals'

const COUNT = 20

const Users = ({
	history,
	language,
	userId,
	privileges,
	scenarios,
	toast,
	users,
	fetching,
	fetched,
	updating,
	creating,
	deleting,
	message,
	error,
	fetch,
	create,
	update,
	remove
}) => {
	const [page, setPage] = useState(1)
	const [searchText, setSearchText] = useState('')
	const [_users, setUsers] = useState([])
	const [user, setUser] = useState(null)
	const [deleteUser, setDeleteUser] = useState(null)

	useEffect(() => {
		if (!privileges.includes('users-write')) {
			history.push('/')
			return
		}

		if (!fetched && !fetching)
			fetch()
	}, [fetch, fetched, fetching, history, privileges])

	useEffect(() => {
		setUsers([].concat(users)
			.filter(u => `${u.name} ${u.username}`.search(new RegExp(`${searchText}`, 'i')) > -1)
			.sort((a, b) => new Date(a.createdAt || 0) < new Date(b.createdAt || 0) ? 1 : -1))
	}, [users, searchText])

	useEffect(() => {
		if (message) {
			if ([LITERALS.USER_CREATED[language], LITERALS.USER_UPDATED[language]].includes(message))
				setUser(null)
			else if ([LITERALS.USER_DELETED[language]].includes(message))
				setDeleteUser(null)
		}
	}, [toast, language, message])

	return (
		<Box component='main' sx={styles.main}>
			<CircularBackdrop open={fetching || deleting.length > 0} position='absolute' />

			<Box sx={styles.titleBar}>
				<Typography variant='title' fontWeight='bold'>{LITERALS.USERS[language]}</Typography>
				<Box sx={styles.container}>
					<TextField
						sx={styles.search}
						placeholder={LITERALS.SEARCH[language]}
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
					/>
					<AddButton style={styles.button} color='main' onClick={() => { setUser({}) }} language={language} />
				</Box>
			</Box>

			<Box sx={styles.tableContainer}>
				{
					_users.length > 0
						?
						<>
							<Box sx={styles.table}>
								{
									_users
										.filter((_, idx) => idx < page * COUNT && idx >= (page - 1) * COUNT)
										.map((user, key) => (
											<Box sx={styles.userRow} key={key}>
												<Typography fontWeight='bold' noWrap sx={styles.name}>{user.name} ({user.username})</Typography>
												<Typography variant='body2' sx={styles.createdAt}>{moment(new Date(user.createdAt)).format('DD.MM.YYYY HH:mm')}</Typography>
												<Box sx={styles.buttons}>
													<Box sx={styles.iconContainer} onClick={() => { setUser(user) }}>
														<ModeEditRounded sx={styles.editIcon} />
													</Box>
													{user.id !== userId && <Box sx={styles.iconContainer} onClick={() => { setDeleteUser(user) }}>
														<DeleteForeverRounded sx={styles.deleteIcon} />
													</Box>}
												</Box>
											</Box>
										))
								}
							</Box>

							<Pagination count={Math.ceil(_users.length / COUNT)} color='primary' size='medium' page={page} onChange={(_, value) => { setPage(value) }} />
						</>
						:
						<Typography sx={styles.text}>{LITERALS.USERS_NOT_FOUND[language]}</Typography>
				}
			</Box>

			{user !== null && <UpsertDialog
				canWrite={privileges.includes('users-write')}
				id={user?.id || '-1'}
				userLanguage={user?.language || 'ru'}
				language={language}
				username={user?.username || ''}
				name={user?.name || ''}
				privileges={user?.privileges || []}
				scenarios={user?.scenarios || []}
				scenariosList={scenarios}
				toast={toast}
				onClose={() => { setUser(null) }}
				updating={updating || creating}
				error={error}
				update={user?.id ? update : create}
			/>}

			{deleteUser !== null && <DeleteDialog
				title={LITERALS.USER_DELETION[language]}
				question={`${LITERALS.DELETE_USER_QUESTION[language]} "${deleteUser.name}"?`}
				yesText={LITERALS.YES[language]}
				cancel={() => { setDeleteUser(null) }}
				deleting={deleting.includes(deleteUser.id)}
				remove={() => { remove(deleteUser.id) }}
			/>}
		</Box>
	)
}

const styles = {
	main: {
		alignItems: 'start',
		display: 'flex',
		flexGrow: 1,
		px: 8,
		flexDirection: 'column',
		minHeight: '100%'
	},
	titleBar: {
		mt: 10,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	search: {
		width: 200,
		mx: 3
	},
	button: {
		m: 0,
		height: 40
	},
	tableContainer: {
		width: '100%',
		my: 4,
		p: 3,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme => theme.palette.background.default,
		border: theme => `1px solid ${theme.palette.primary.headerBorder}`,
		borderRadius: '12px'
	},
	table: {
		width: '100%'
	},
	userRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		mb: 2,
		px: 2.5,
		py: 1.5,
		border: theme => `1px solid ${theme.palette.primary.border}`,
		borderRadius: '10px'
	},
	name: {
		width: 'calc(100% - 332px)'
	},
	createdAt: {
		width: 150
	},
	buttons: {
		width: 150,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'end',
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	editIcon: {
		fontSize: 28,
		color: theme => theme.palette.warning.main
	},
	deleteIcon: {
		fontSize: 28,
		ml: 1,
		color: theme => theme.palette.error.main
	},
	text: {
		my: 8
	}
}

export default Users