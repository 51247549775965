import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Dropdown from '../custom/dropdown'
import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextButton from '../custom/text-button'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'

const RunAPINodeSettings = ({ language, toast, node, api, update, cancel }) => {
	const [id, setId] = useState(null)
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setName(node?.data?.name || '')
			setDescription(node?.data?.description || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!name) {
			toast(LITERALS.PLEASE_SELECT_API[language], { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, description, name } })
	}, [description, language, node, name, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.API_SCENARIO[language]} active color={theme.palette.primary.runApiColor} onClick={() => { }} />
				</Box>
				<Box sx={styles.body}>
					<Dropdown
						container={styles.container}
						selected={name}
						items={api.map(nd => ({ id: nd.data.name, name: nd.data.name }))}
						onChange={item => setName(item)}
					/>
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
		mb: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 239px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		py: 1
	},
	container: {
		px: 2
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default RunAPINodeSettings