import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Close, DeleteForeverRounded, DownloadRounded, FolderRounded } from '@mui/icons-material'
import * as moment from 'moment'

import CircularBackdrop from '../custom/circular-backdrop'
import LITERALS from '../../helpers/literals'
import DeleteDialog from '../custom/delete-dialog'

const VersionsDialog = ({ language, scenarioId, versions, loading, onClose, get, loadData, remove }) => {
	const [initialized, setInitialized] = useState(false)
	const [removeId, setRemoveId] = useState(null)

	useEffect(() => {
		if (!initialized) {
			setInitialized(true)
			get(scenarioId)
		}
	}, [get, initialized, scenarioId])

	return (
		<Dialog open={true} maxWidth='md' fullWidth={true}>
			<DialogTitle sx={styles.title}>
				<Typography variant='title' fontWeight='bold'>
					{LITERALS.VERSION_MANAGE[language]}
				</Typography>
				<IconButton aria-label='close' onClick={onClose} sx={styles.closeIcon}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box sx={styles.box}>
					{
						versions.length === 0
							?
							<Box sx={styles.noData}>
								<FolderRounded sx={styles.noDataIcon} />
								<Typography variant='title' fontWeight='bold'>{LITERALS.NOTHING_HERE[language]}</Typography>
							</Box>
							:
							versions.map((version, idx) => (
								<Version
									key={idx}
									{...version}
									load={() => {
										loadData(version.scenario, version.id)
										onClose()
									}}
									remove={() => { setRemoveId(version.id) }}
								/>
							))
					}
				</Box>
				<CircularBackdrop open={loading} position='absolute' />
			</DialogContent>

			{removeId !== null && <DeleteDialog
				title={LITERALS.VERSION_DELETION[language]}
				question={LITERALS.VERSION_DELETE_QUESTION[language]}
				yesText={LITERALS.YES[language]}
				cancel={() => { setRemoveId(null) }}
				deleting={loading}
				remove={() => {
					remove(scenarioId, removeId)
					setRemoveId(null)
				}}
			/>}
		</Dialog>
	)
}

const Version = ({ name, description, createdAt, load, remove }) => (
	<Box sx={styles.main}>
		<Box sx={styles.item}>
			<Typography fontWeight='bold' noWrap>{name}</Typography>
			<Typography variant='body3' color='primary.border' noWrap>{description || '---'}</Typography>
		</Box>
		<Typography sx={styles.datetime} variant='body2' fontWeight='bold' align='right' noWrap>
			{moment(new Date(createdAt)).format('DD.MM.YYYY HH:mm')}
		</Typography>

		<Box sx={styles.buttons}>
			<IconButton aria-label='close' onClick={load}>
				<DownloadRounded sx={styles.loadIcon} />
			</IconButton>

			<IconButton onClick={remove}>
				<DeleteForeverRounded sx={styles.deleteIcon} />
			</IconButton>
		</Box>
	</Box>
)

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	box: {
		width: '100%',
		my: 3,
		display: 'flex',
		flexDirection: 'column'
	},
	noData: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		p: 2
	},
	noDataIcon: {
		mb: 2,
		fontSize: 40,
		color: theme => theme.palette.primary.gray
	},
	main: {
		width: '100%',
		px: 3,
		py: 1.5,
		mt: 3,
		borderRadius: '12px',
		border: theme => `1px solid ${theme.palette.primary.border}`,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer',
		'&:hover': {
			border: theme => `1px solid ${theme.palette.primary.main}`,
		}
	},
	item: {
		width: `calc(100% - 278px)`,
		display: 'flex',
		flexDirection: 'column'
	},
	datetime: {
		width: 130,
		mr: 4
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttons: {
		width: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	loadIcon: {
		fontSize: 28,
		color: theme => theme.palette.primary.dark
	},
	deleteIcon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	}
}

export default VersionsDialog