import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { CheckRounded, JavascriptRounded } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextButton from '../custom/text-button'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'
import TooltipTitle from '../custom/tooltip-title'
import GenerateCodeDialog from '../custom/generate-code-dialog'
import CheckCodeDialog from '../custom/check-code-dialog'

const ResultNodeSettings = ({ language, toast, node, update, cancel, code, generating, generate, optimizedCode, checking, check }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [result, setResult] = useState('')
	const [showGenerateDialog, setShowGenerateDialog] = useState(false)
	const [showTestDialog, setShowTestDialog] = useState(false)
	const [instruction, setInstruction] = useState('')
	const [example, setExample] = useState('')
	const [testCode, setTestCode] = useState('')
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setResult(node?.data?.result || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!result) {
			toast(LITERALS.PLEASE_ENTER_RESULT_TEXT[language], { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, description, result } })
	}, [description, language, node, result, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.RESULT_TEXT[language]} active color={theme.palette.primary.resultColor} onClick={() => { }} />
					<Box>
						<Tooltip title={<TooltipTitle title={LITERALS.GENERATE_CODE[language]} />} arrow>
							<Button variant='contained' size='small' color='primary' sx={styles.button2} onClick={() => { setShowGenerateDialog(true) }}>
								<JavascriptRounded sx={styles.icon} />
							</Button>
						</Tooltip>

						<Tooltip title={<TooltipTitle title={LITERALS.CHECK_CODE[language]} />} arrow>
							<IconButton color='success' onClick={() => { setTestCode(result); setShowTestDialog(true) }}>
								<CheckRounded />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>

				<Box sx={styles.body}>
					<TextArea
						rows={6}
						placeholder={`'What day and time would you like to sign up for instructor "' + this.instructor + '" lesson "' + this.lesson + '"?'`}
						value={result}
						onChange={e => setResult(e.target.value)}
					/>
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>

			{showGenerateDialog && <GenerateCodeDialog
				language={language}
				type={node.type}
				instruction={instruction}
				setInstruction={setInstruction}
				example={example}
				setExample={setExample}
				code={code}
				generating={generating}
				cancel={() => { setShowGenerateDialog(false) }}
				generate={generate}
				apply={() => { setResult(code); setShowGenerateDialog(false) }}
			/>}

			{showTestDialog && <CheckCodeDialog
				language={language}
				type={node.type}
				code={testCode}
				setCode={setTestCode}
				optimized={optimizedCode}
				checking={checking}
				cancel={() => { setShowTestDialog(false) }}
				check={check}
				apply={() => { setResult(optimizedCode); setShowTestDialog(false) }}
			/>}
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
		mb: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 239px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		px: 2,
		py: 1
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	},
	button2: {
		mr: 2,
		p: 0,
		borderRadius: 10
	},
	icon: {
		fontSize: 30
	}
}

export default ResultNodeSettings