import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Users from '../../components/users'
import { create, fetch, remove, update } from '../../actions/users'

const mapStateToProps = state => {
	return {
		userId: state.profile.id,
		language: state.profile.language,
		privileges: state.profile.privileges,
		scenarios: state.scenarios.scenarios,
		users: state.users.users,
		fetching: state.users.fetching,
		fetched: state.users.fetched,
		updating: state.users.updating,
		creating: state.users.creating,
		deleting: state.users.deleting,
		message: state.users.message,
		error: state.users.error
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetch: () => dispatch(fetch(ownProps.history.push)),
		create: data => dispatch(create(data, ownProps.history.push)),
		update: data => dispatch(update(data, ownProps.history.push)),
		remove: id => dispatch(remove(id, ownProps.history.push))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users))