import React, { useState } from 'react'
import { Box, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material'
import { ArrowDropDownRounded } from '@mui/icons-material'

const GroupButton = ({ main, secondary }) => {
	const [anchor, setAnchor] = useState(null)

	return <Box sx={styles.main}>
		<Box sx={styles.primary} onClick={e => { if (!main.disabled) main.onClick(e) }}>
			<Typography variant='h6' color='primary.contrastText'>{main.title}</Typography>
		</Box>
		<Box sx={styles.secondary}>
			<Box sx={styles.iconContainer} onClick={e => { setAnchor(e.currentTarget) }}>
				<ArrowDropDownRounded sx={styles.icon} />
			</Box>
			<Popover
				keepMounted
				sx={styles.popover}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 185 }}
				anchorEl={anchor}
				open={!!anchor}
				onClose={() => { setAnchor(null) }}
			>
				<List sx={styles.paper}>
					{
						secondary.map((item, key) => (
							<ListItem key={key} disablePadding>
								{
									item.input
										?
										<>
											<input
												type='file'
												id={item.input}
												accept={item.accept}
												style={styles.input}
												onChange={e => {
													setAnchor(null)
													item.onClick(e)
												}}
											/>
											<label htmlFor={item.input} style={styles.w100}>
												<ListItemButton>
													<ListItemText primary={item.title} sx={styles.listItem} />
												</ListItemButton>
											</label>
										</>
										:
										<ListItemButton onClick={e => { setAnchor(null); item.onClick(e) }}>
											<ListItemText primary={item.title} sx={styles.listItem} />
										</ListItemButton>
								}
							</ListItem>
						))
					}
				</List>
			</Popover>

		</Box>
	</Box>
}

const styles = {
	main: {
		height: 40,
		display: 'flex',
		position: 'relative',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: '40px',
		backgroundColor: theme => theme.palette.primary.contrastText,
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`
	},
	primary: {
		px: 2,
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderTopLeftRadius: '20px',
		borderBottomLeftRadius: '20px',
		backgroundColor: theme => theme.palette.primary.main,
		borderRight: theme => `1px solid ${theme.palette.primary.contrastText}`,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme => `${theme.palette.primary.main}bf`
		},
		'&:active': {
			backgroundColor: theme => theme.palette.primary.main
		}
	},
	secondary: {
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		borderTopRightRadius: '20px',
		borderBottomRightRadius: '20px',
		backgroundColor: theme => theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#0014ffbf'
		},
		'&:active': {
			backgroundColor: '#0014ff'
		}
	},
	iconContainer: {
		px: 0.5,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 32,
		color: theme => theme.palette.primary.contrastText
	},
	popover: {
		mt: 1
	},
	paper: {
		width: 225
	},
	listItem: {
		'& > .MuiTypography-root': {
			fontSize: 14
		}
	},
	input: {
		display: 'none'
	},
	w100: {
		width: '100%'
	}
}

export default GroupButton