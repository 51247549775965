import { Backdrop, CircularProgress } from '@mui/material'

const CircularBackdrop = ({ open, position }) => (
	<Backdrop sx={{ ...styles.backdrop, position: position || 'fixed' }} open={open}>
		<CircularProgress color='inherit' />
	</Backdrop>
)

const styles = {
	backdrop: {
		backgroundColor: theme => `${theme.palette.primary.contrastText}bf`,
		color: theme => theme.palette.primary.main,
		zIndex: (theme) => theme.zIndex.drawer + 1
	}
}

export default CircularBackdrop