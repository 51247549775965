import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'

const Tabs = ({ language, tabs, width, value, setValue }) => {
	const [left, setLeft] = useState(2)

	useEffect(() => {
		if (value === 0)
			setLeft(2)
		else if (value === tabs.length - 1)
			setLeft(width - Math.floor(width / tabs.length) - 2)
		else
			setLeft(value * Math.floor(width / tabs.length))
	}, [tabs, value, width])

	return <Box sx={{ ...styles.main, width }}>
		{
			tabs.map((tab, key) => (
				<Box key={key} sx={{ width: Math.floor(width / tabs.length) }} onClick={() => { setValue(key) }}>
					<Typography variant='body2' textAlign='center'>{tab[language]}</Typography>
				</Box>
			))
		}
		<Box sx={{ ...styles.active, width: Math.floor(width / tabs.length), left }}>
			<Typography variant='body2'>{tabs[value][language]}</Typography>
		</Box>
	</Box>
}

const styles = {
	main: {
		position: 'relative',
		overflow: 'hidden',
		backgroundColor: theme => theme.palette.primary.gray2,
		borderRadius: '100px',
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer' 
	},
	active: {
		position: 'absolute',
		transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		backgroundColor: theme => theme.palette.primary.contrastText,
		height: 36,
		borderRadius: '100px',
		boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}

export default Tabs