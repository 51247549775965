import { ACTION_TYPES } from '../actions/scenarios'
import { ACTION_TYPES as ACTION_TYPES_LOGIN } from '../actions/login'
import { ACTION_TYPES as ACTION_TYPES_SOCKET } from '../actions/socket'

const initialState = {
	scenarios: [],
	scenario: [],
	fetching: false,
	fetched: false,
	updating: false,
	creating: false,
	lastCreated: null,
	deleting: [],
	recipient: '',
	history: [],
	testing: false,
	versions: [],
	versionLoading: false,
	message: '',
	error: ''
}

const scenarios = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_SCENARIOS_INIT:
			return {
				...state,
				scenarios: [],
				scenario: [],
				versions: [],
				fetching: true,
				fetched: false,
				message: '',
				error: ''
			}
		case ACTION_TYPES.FETCH_SCENARIOS_SUCCESS:
			return {
				...state,
				scenarios: JSON.parse(JSON.stringify(action.data)),
				fetching: false,
				fetched: true
			}
		case ACTION_TYPES.FETCH_SCENARIOS_FAIL:
			return {
				...state,
				error: action.error,
				fetching: false,
				fetched: false
			}
		case ACTION_TYPES.GET_SCENARIO_INIT:
			return {
				...state,
				scenario: [],
				versions: [],
				fetching: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.GET_SCENARIO_SUCCESS:
			return {
				...state,
				scenario: JSON.parse(JSON.stringify(action.data)),
				recipient: action.recipient,
				fetching: false
			}
		case ACTION_TYPES.GET_SCENARIO_FAIL:
			return {
				...state,
				error: action.error,
				fetching: false
			}
		case ACTION_TYPES.CREATE_SCENARIO_INIT:
			return {
				...state,
				creating: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.CREATE_SCENARIO_SUCCESS:
			return {
				...state,
				lastCreated: action.data.id,
				scenarios: [{ ...action.data }, ...state.scenarios],
				message: action.message,
				creating: false
			}
		case ACTION_TYPES.CREATE_SCENARIO_FAIL:
			return {
				...state,
				error: action.error,
				creating: false
			}
		case ACTION_TYPES.UPDATE_SCENARIO_INIT:
			return {
				...state,
				updating: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.UPDATE_SCENARIO_SUCCESS:
			return {
				...state,
				scenarios: [...state.scenarios.map(scenario => scenario.id === action.data.id ? { ...scenario, ...action.data } : scenario)],
				scenario: action.scenario || state.scenario,
				message: action.message,
				updating: false
			}
		case ACTION_TYPES.UPDATE_SCENARIO_FAIL:
			return {
				...state,
				error: action.error,
				updating: false
			}
		case ACTION_TYPES.DELETE_SCENARIO_INIT:
			return {
				...state,
				deleting: [...state.deleting, action.id],
				message: '',
				error: ''
			}
		case ACTION_TYPES.DELETE_SCENARIO_SUCCESS:
			return {
				...state,
				deleting: [...state.deleting.filter(del => del !== action.id)],
				scenarios: [...state.scenarios.filter(scenario => scenario.id !== action.id)],
				message: action.message
			}
		case ACTION_TYPES.DELETE_SCENARIO_FAIL:
			return {
				...state,
				deleting: [...state.deleting.filter(del => del !== action.id)],
				error: action.error
			}
		case ACTION_TYPES.GET_VERSIONS_INIT:
			return {
				...state,
				versions: [],
				versionLoading: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.GET_VERSIONS_SUCCESS:
			return {
				...state,
				versions: [...action.versions],
				versionLoading: false
			}
		case ACTION_TYPES.GET_VERSIONS_FAIL:
			return {
				...state,
				versionLoading: false,
				error: action.error
			}
		case ACTION_TYPES.GET_VERSION_INIT:
			return {
				...state,
				scenario: [],
				fetching: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.GET_VERSION_SUCCESS:
			return {
				...state,
				scenario: JSON.parse(JSON.stringify(action.data)),
				recipient: action.recipient,
				fetching: false
			}
		case ACTION_TYPES.GET_VERSION_FAIL:
			return {
				...state,
				error: action.error,
				fetching: false
			}
		case ACTION_TYPES.UPSERT_VERSION_INIT:
			return {
				...state,
				versionLoading: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.UPSERT_VERSION_SUCCESS:
			return {
				...state,
				versions: [{ ...action.data }, ...state.versions],
				message: action.message,
				versionLoading: false
			}
		case ACTION_TYPES.UPSERT_VERSION_FAIL:
			return {
				...state,
				versionLoading: false,
				error: action.error
			}
		case ACTION_TYPES.DELETE_VERSION_INIT:
			return {
				...state,
				versionLoading: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.DELETE_VERSION_SUCCESS:
			return {
				...state,
				versionLoading: false,
				versions: [...state.versions.filter(version => version.id !== action.id)],
				message: action.message
			}
		case ACTION_TYPES.DELETE_VERSION_FAIL:
			return {
				...state,
				versionLoading: false,
				error: action.error
			}
		case ACTION_TYPES.TEST_SCENARIO_INIT:
			return {
				...state,
				history: [
					...state.history,
					{ role: 'user', content: action.message, datetime: new Date().toISOString() }
				],
				testing: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.TEST_SCENARIO_SUCCESS:
			return {
				...state,
				testing: false,
			}
		case ACTION_TYPES.TEST_SCENARIO_FAIL:
			return {
				...state,
				testing: false,
				error: action.error
			}
		case ACTION_TYPES.RESET_TEST_HISTORY:
			return {
				...state,
				recipient: action.recipient,
				history: []
			}
		case ACTION_TYPES_SOCKET.LOG_TRIGGERED:
			if (state.recipient !== action.payload.topic)
				return state

			return {
				...state,
				history: [
					...state.history,
					JSON.parse(action.payload.message)
				]
			}
		case ACTION_TYPES.RESET_MESSAGES:
			return {
				...state,
				lastCreated: null,
				message: '',
				error: ''
			}
		case ACTION_TYPES_LOGIN.LOGOUT:
			return {
				...state,
				...initialState
			}
		default:
			return state
	}
}

export default scenarios