import React, { useState } from 'react'
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { CheckRounded, DeleteForeverRounded, JavascriptRounded } from '@mui/icons-material'

import LITERALS from '../../helpers/literals'
import TooltipTitle from './tooltip-title'
import GenerateCodeDialog from '../custom/generate-code-dialog'
import CheckCodeDialog from '../custom/check-code-dialog'
import TextArea from './text-area'

const ConditionItem = ({ language, title, divider, name, value, isElse, valueChanged, onDelete, type, code, generating, generate, optimizedCode, checking, check }) => {
	const [showGenerateDialog, setShowGenerateDialog] = useState(false)
	const [showTestDialog, setShowTestDialog] = useState(false)
	const [instruction, setInstruction] = useState('')
	const [testCode, setTestCode] = useState('')

	return <Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>

		<Box sx={styles.container}>
			<Box sx={styles.left}>
				<TextField
					fullWidth
					disabled={!!isElse}
					placeholder='Instructor check'
					value={isElse ? LITERALS.DEFAULT[language] : name}
					onChange={e => valueChanged('name', e.target.value)}
				/>
			</Box>

			<Box sx={styles.right}>
				<TextArea
					rows={3}
					placeholder={!!isElse ? 'else' : '!!variables.instructor && !variables.lesson'}
					disabled={!!isElse}
					value={value}
					onChange={e => valueChanged('value', e.target.value)}
				/>
			</Box>

			{
				!!isElse
					?
					<Box sx={styles.emptyIcon} />
					:
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Tooltip title={<TooltipTitle title={LITERALS.GENERATE_CODE[language]} />} arrow>
							<Button variant='contained' size='small' color='primary' sx={styles.button2} onClick={() => { setShowGenerateDialog(true) }}>
								<JavascriptRounded sx={styles.icon2} />
							</Button>
						</Tooltip>

						<Tooltip title={<TooltipTitle title={LITERALS.CHECK_CODE[language]} />} arrow>
							<IconButton color='success' onClick={() => { setTestCode(value); setShowTestDialog(true) }}>
								<CheckRounded />
							</IconButton>
						</Tooltip>

						<Box sx={styles.iconContainer} onClick={onDelete}>
							<DeleteForeverRounded sx={styles.icon} />
						</Box>
					</Box>
			}
		</Box>

		{showGenerateDialog && <GenerateCodeDialog
			language={language}
			type={type}
			instruction={instruction}
			setInstruction={setInstruction}
			code={code}
			generating={generating}
			cancel={() => { setShowGenerateDialog(false) }}
			generate={generate}
			apply={() => { valueChanged('value', code); setShowGenerateDialog(false) }}
		/>}

		{showTestDialog && <CheckCodeDialog
			language={language}
			type={type}
			code={testCode}
			setCode={setTestCode}
			optimized={optimizedCode}
			checking={checking}
			cancel={() => { setShowTestDialog(false) }}
			check={check}
			apply={() => { valueChanged('value', optimizedCode); setShowTestDialog(false) }}
		/>}
	</Box>
}

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'start',
		justifyContent: 'space-between'
	},
	left: {
		width: '30%'
	},
	right: {
		width: 'calc(70% - 156px)'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	emptyIcon: {
		width: 140
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	},
	button2: {
		mr: 1,
		p: 0,
		height: 30,
		borderRadius: 10
	},
	icon2: {
		fontSize: 30
	}
}

export default ConditionItem