import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { Close, Help } from '@mui/icons-material'

import CircularBackdrop from '../custom/circular-backdrop'
import LITERALS from '../../helpers/literals'
import TextArea from './text-area'
import TooltipTitle from './tooltip-title'

const GenerateCodeDialog = ({ language, type, instruction, setInstruction, example = 'example', setExample, code, generating, cancel, generate, apply }) => (
	<Dialog open={true} maxWidth='md' fullWidth={true}>
		<DialogTitle sx={styles.title}>
			<Typography variant='title' fontWeight='bold'>{LITERALS.GENERATE_CODE[language]}</Typography>
			<IconButton aria-label='close' onClick={cancel} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers>
			<Box sx={styles.body}>
				<Box sx={styles.w100}>
					<Box sx={styles.container}>
						<Typography fontWeight='bold' variant='h6' sx={styles.text2} noWrap>{LITERALS.INSTRUCTION[language]}</Typography>
						<Tooltip title={<TooltipTitle title={LITERALS.INSTRUCTION_HELP[language]} />} arrow>
							<Help sx={styles.helpIcon} />
						</Tooltip>
					</Box>
					<TextArea
						rows={5}
						placeholder={LITERALS.INSTRUCTION_PLACEHOLDER[language]}
						value={instruction}
						onChange={e => setInstruction(e.target.value)}
					/>
				</Box>

				{['getResult', 'chatAI'].includes(type) && <Box sx={styles.w100}>
					<Box sx={styles.container}>
						<Typography fontWeight='bold' variant='h6' sx={styles.text2} noWrap>{LITERALS.RETURN_EXAMPLE[language]}</Typography>
						<Tooltip title={<TooltipTitle title={LITERALS.RETURN_EXAMPLE_HELP[language]} />} arrow>
							<Help sx={styles.helpIcon} />
						</Tooltip>
					</Box>
					<TextArea
						rows={5}
						placeholder={LITERALS.RETURN_EXAMPLE_PLACEHOLDER[language]}
						value={example}
						onChange={e => setExample(e.target.value)}
					/>
				</Box>}

				<Box sx={styles.w100}>
					<Typography fontWeight='bold' variant='h6' sx={styles.text2} noWrap>{LITERALS.GENERATED_CODE[language]}</Typography>
					<TextArea rows={4} value={code} onChange={() => { }} />
				</Box>
			</Box>
			<CircularBackdrop open={generating} position='absolute' />
		</DialogContent>
		<DialogActions sx={styles.p2}>
			{
				(!instruction || (['getResult', 'chatAI'].includes(type) && !example))
					?
					<Tooltip title={<TooltipTitle title={!instruction ? LITERALS.PLEASE_ENTER_INSTRUCTION[language] : LITERALS.PLEASE_ENTER_RETURN_EXAMPLE[language]} />} arrow>
						<span><Button disabled variant='contained' onClick={() => { }}>{LITERALS.GENERATE[language]}</Button></span>
					</Tooltip>
					:
					<Button disabled={generating} variant='contained' onClick={() => { generate({ type, instruction, example }) }}>{LITERALS.GENERATE[language]}</Button>
			}

			<Button disabled={!code} variant='contained' onClick={apply}>{LITERALS.APPLY_CODE[language]}</Button>
		</DialogActions>
	</Dialog>
)

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	text: {
		width: '100%',
		p: 1
	},
	text2: {
		my: 1.25,
		mr: 2
	},
	p2: {
		p: 2
	},
	helpIcon: {
		color: theme => theme.palette.primary.border,
		fontSize: 18,
		ml: 1,
		cursor: 'pointer'
	},
	body: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	w100: {
		width: '100%'
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	}
}

export default GenerateCodeDialog