import React from 'react'
import { Box, Checkbox, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded } from '@mui/icons-material'

import Dropdown from './dropdown'
import { formats, propTypes } from '../../helpers/constants'
import LITERALS from '../../helpers/literals'

const PropertyItem = ({ language, title, divider, name, type, required, format, example, description, valueChanged, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Box sx={styles.title}>
			<Typography fontWeight='bold' variant='h6'>{title}</Typography>
			<Box sx={styles.checkbox}>
				<Checkbox color='primary' checked={!!required} onChange={e => valueChanged('required', e.target.checked)} />
				<Typography variant='h6'>{LITERALS.REQUIRED_FIELD[language]}</Typography>
			</Box>
		</Box>

		<Box sx={styles.container}>
			<Box sx={styles.row}>
				<Box sx={styles.subRow}>
					<Box sx={styles.name}>
						<TextField
							fullWidth
							placeholder={`${LITERALS.NAME_2[language]}: startdate`}
							value={name}
							onChange={e => valueChanged('name', e.target.value)}
						/>
					</Box>

					<Box sx={styles.description}>
						<TextField
							fullWidth
							placeholder='Description: The date the user wants to sign up for the class. The date must be in the format YYYY-MM-DD.'
							value={description}
							onChange={e => valueChanged('description', e.target.value)}
						/>
					</Box>
				</Box>

				<Box sx={styles.subRow}>
					<Box sx={styles.item1}>
						<Dropdown selected={type} items={propTypes} onChange={t => valueChanged('type', t)} />
					</Box>

					<Box sx={styles.item1}>
						<Dropdown selected={format} items={formats.map(f => ({...f, name: f.name[language]}))} onChange={f => valueChanged('format', f)} />
					</Box>

					<Box sx={styles.item2}>
						<TextField
							fullWidth
							placeholder={`${LITERALS.EXAMPLE[language]}: 2023-07-23`}
							value={example}
							onChange={e => valueChanged('example', e.target.value)}
						/>
					</Box>
				</Box>
			</Box>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.icon} />
			</Box>
		</Box>
	</Box>
)

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		display: 'flex',
		alignItems: 'center'
	},
	checkbox: {
		display: 'flex',
		ml: 2,
		alignItems: 'center'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	row: {
		width: 'calc(100% - 44px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	subRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	name: {
		width: 160,
		pb: 1.25
	},
	description: {
		width: 'calc(100% - 176px)',
		pb: 1.25
	},
	item1: {
		width: 160,
	},
	item2: {
		width: 'calc(100% - 352px)',
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	}
}

export default PropertyItem