import { v4 as uuidv4 } from 'uuid'
import LITERALS from './literals'

export const checkEdges = (language, source, target, edges, newEdge) => {
	if (source?.type !== 'start' && edges.find(edge => edge.source === source?.id && edge.target === target?.id))
		return LITERALS.ONLY_ONE_EDGE_ERROR_1[language]

	if (edges.find(edge => edge.source === newEdge.source && edge.sourceHandle === newEdge.sourceHandle))
		return LITERALS.ONLY_ONE_EDGE_ERROR_2[language]

	if (!['start', 'checkConditions'].includes(source?.type) && edges.find(edge => edge.source === newEdge.source))
		return LITERALS.ONLY_ONE_EDGE_ERROR_3[language]

	return null
}

export const initialData = type => {
	switch (type) {
		case 'api':
			return { name: '', webhooks: [], conditions: [], actions: [] }
		case 'chatAI':
			return { description: '', role: 'system', content: '', destinationPath: '' }
		case 'checkConditions':
			return { description: '', conditions: [{ id: uuidv4(), name: '', value: '', isElse: true }] }
		case 'checkParams':
			return { description: '', params: [] }
		case 'filter':
			return { description: '', sourceName: '', destinationPath: '', condition: '' }
		case 'mapper':
			return { description: '', sourceName: '', destinationPath: '', values: [] }
		case 'getResult':
			return { description: '', result: '' }
		case 'runScenario':
			return { name: '', description: '' }
		case 'save_variables':
			return { description: '', values: [] }
		case 'start':
			return { description: '', history: 2048, promts: [], funcs: [] }
		default: return {}
	}
}

export const arraysEqual = (a, b) => {
	if (a === b) return true
	if (a.length !== b.length) return false
	for (const item of a) {
		if (!b.includes(item)) return false
	}
	return true
}

export const parseLog = (language, log, params) => {
	let text = LITERALS[log][language]
	for (let i = 0; i < params.length; i++)
		text = text.replace(`%${i + 1}`, `${params[i]}`)

	return text
}