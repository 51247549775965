import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, InputAdornment, OutlinedInput, Paper, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import LITERALS from '../../helpers/literals'

const Login = ({ language, error, logging, toast, login }) => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)

	useEffect(() => {
		if (error)
			toast(error, { type: 'error' })
	}, [error, toast])

	const onLogin = useCallback(() => {
		if (username.length === 0) {
			toast(LITERALS.PLEASE_ENTER_USERNAME_2[language], { type: 'error' })
			return
		}

		if (password.length === 0) {
			toast(LITERALS.PLEASE_ENTER_PASSWORD[language], { type: 'error' })
			return
		}

		login({ username, password })
	}, [language, login, password, toast, username])

	return (
		<Box component='main' sx={styles.main}>
			<Box sx={styles.container}>
				<Box sx={styles.topContainer}>
					<Box sx={styles.logoBox}>
						<img
							src={`/static/images/intellectdialog.png`}
							alt='Logo not loaded'
							style={styles.logo}
						/>
					</Box>

					<Paper elevation={2} sx={styles.paper}>
						<Box sx={styles.title}>
							<Typography variant='h4' fontWeight={600} color='primary.main' sx={styles.mb1}>{LITERALS.WELCOME[language]}</Typography>
							<Typography color='secondary.dark'>{LITERALS.ENTER_SYSTEM[language]}</Typography>
						</Box>
						<Box sx={styles.mt3}>
							<Box sx={styles.mb2}>
								<Typography variant='body2' color='text.primary'>{LITERALS.USERNAME[language]}</Typography>
								<TextField
									autoFocus
									fullWidth
									size='small'
									placeholder={LITERALS.ENTER_USERNAME[language]}
									value={username}
									onChange={e => { setUsername(e.target.value) }}
									variant='outlined'
								/>
							</Box>
							<Box sx={styles.mb2}>
								<Typography variant='body2' color='text.primary'>{LITERALS.PASSWORD[language]}</Typography>
								<OutlinedInput
									fullWidth
									size='small'
									sx={styles.input}
									placeholder={LITERALS.ENTER_PASSWORD[language]}
									value={password}
									type={showPassword ? 'text' : 'password'}
									onChange={e => { setPassword(e.target.value) }}
									onKeyDown={e => { if (e.key === 'Enter') onLogin() }}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												sx={styles.iconButton}
												aria-label='toggle password visibility'
												onClick={() => { setShowPassword(!showPassword) }}
												onMouseDown={() => { }}
												edge='end'
											>
												{showPassword ? <VisibilityOff sx={styles.icon} /> : <Visibility sx={styles.icon} />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</Box>
							<Button variant='contained' color='primary' disabled={logging} sx={styles.button} onClick={onLogin}>
								{LITERALS.LOGIN[language]}
							</Button>
						</Box>
					</Paper>
				</Box>

				<Box sx={styles.footer}>
					<Typography variant='body2'>© 2025 IntellectDialog</Typography>
				</Box>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		mx: 12,
		alignItems: 'start',
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		minHeight: '100%'
	},
	icon: {
		fontSize: 18,
		color: theme => theme.palette.secondary.dark
	},
	container: {
		alignItems: 'start',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		minWidth: 600
	},
	topContainer: {
		pb: 7.5,
		px: 10,
		width: '100%',
		minWidth: 600,
		display: 'flex',
		zIndex: 9999,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	logoBox: {
		mt: 6,
		mb: 3,
		width: '100%',
		minWidth: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	logo: {
		width: 'auto',
		height: 100
	},
	paper: {
		backgroundColor: theme => theme.palette.background.default,
		width: 450,
		p: 4
	},
	footer: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		right: 0,
		height: 60,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	title: {
		mt: 1,
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center'
	},
	mb1: {
		mb: 1
	},
	mb2: {
		mb: 2
	},
	mt3: {
		mt: 3
	},
	button: {
		width: '100%',
		mb: 2
	},
	input: {
		pr: 0
	},
	iconButton: {
		mr: 1
	}
}

export default Login