import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Collapse, IconButton, Typography } from '@mui/material'
import { ArrowForwardRounded, Close } from '@mui/icons-material'
import * as moment from 'moment'

import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'
import { parseLog } from '../../helpers/utils'

const TestDialog = ({
	language,
	open,
	cancel,
	scenarioId,
	scenarioData,
	recipient,
	history,
	testing,
	test,
	reset,
	toast
}) => {
	const [message, setMessage] = useState('')

	const onTest = useCallback(() => {
		if (testing) {
			toast(LITERALS.WAIT_UNTIL_PROCESS_END[language], { type: 'error' })
			return
		}

		if (!message.trim()) {
			toast(LITERALS.PLEASE_ENTER_TEXT[language], { type: 'error' })
			return
		}

		test({
			id: scenarioId,
			data: scenarioData,
			recipient,
			message: message.trim(),
			history: history.filter(h => ['user', 'assistant'].includes(h.role))
		})
		setMessage('')
	}, [history, language, message, recipient, scenarioData, scenarioId, test, testing, toast])

	return <Box sx={styles.main}>
		<Collapse in={open} timeout='auto' unmountOnExit orientation='horizontal' sx={styles.collapse}>
			<Box sx={styles.collapseContainer}>
				<Box sx={styles.collapseTitle}>
					<Typography variant='h4' fontWeight='bold' noWrap>{LITERALS.TEST_TERMINAL[language]}</Typography>
					<IconButton aria-label='close' onClick={cancel} sx={styles.closeIcon}>
						<Close />
					</IconButton>
				</Box>

				<Box sx={styles.collapseBody}>
					<Box sx={styles.body}>
						<Box sx={styles.dialogueContainer}>
							<Box sx={styles.dialogue}>
								{history.map((item, key) => <Message key={key} language={language} {...item} />)}
							</Box>
						</Box>

						{history.length > 0 && (
							<Button
								variant='contained'
								color='primary'
								size='small'
								disabled={testing}
								sx={styles.button}
								onClick={reset}
							>
								{LITERALS.RESET_DIALOG[language]}
							</Button>
						)}

						<Box sx={styles.textAreaContainer}>
							<TextArea
								styles={styles.textArea}
								rows={2}
								placeholder={LITERALS.MESSAGE_TO_BOT[language]}
								value={message}
								onChange={e => { setMessage(e.target.value) }}
							/>
							<Box sx={styles[`sendButton${(testing || !message) ? 'Disabled' : ''}`]} onClick={onTest}>
								{testing ? <CircularProgress /> : <ArrowForwardRounded />}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Collapse>
	</Box>
}

const Message = ({ language, role, content, datetime }) => {
	const [text, setText] = useState(content)
	useEffect(() => {
		try {
			if (['log', 'error'].includes(role)) {
				const data = JSON.parse(content)
				setText(parseLog(language, data.log, data.params))
			}
		} catch { }
	}, [role, content, language])

	return <Box sx={styles[`${role === 'user' ? 'userM' : 'm'}essageContainer`]}>
		<Box sx={styles[`${role === 'user' ? 'userM' : 'm'}essageBox`]}>
			<Box sx={styles.flex}>
				<Box sx={styles[`${role}Border`]} />
				<pre style={styles.messageText}>{text}</pre>
			</Box>
			<Typography variant='body3' color='primary.disabled'>
				{moment(new Date(datetime)).format('HH:mm')}
			</Typography>
		</Box>
	</Box>
}

const styles = {
	main: {
		position: 'fixed',
		left: 0,
		top: 0,
		bottom: 0,
		zIndex: 3,
		display: 'flex',
		flexDirection: 'row'
	},
	closeIcon: {
		color: theme => theme.palette.primary.heading
	},
	body: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	textAreaContainer: {
		width: '100%',
		p: 2,
		backgroundColor: theme => theme.palette.primary.chat,
		borderRadius: '12px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	textArea: {
		minWidth: 'calc(100% - 56px)',
		maxWidth: 'calc(100% - 56px)',
		width: 'calc(100% - 56px)',
		height: 58,
		maxHeight: 58,
		minHeight: 58,
		boxSizing: 'border-box',
		borderColor: 'transparent',
		outline: '1px solid transparent',
		backgroundColor: 'transparent',
		resize: 'none'
	},
	sendButton: {
		width: 32,
		height: 32,
		backgroundColor: theme => theme.palette.primary.black,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		opacity: 1,
		'& .MuiSvgIcon-root': {
			fontSize: 22,
			color: theme => theme.palette.primary.contrastText,
			transform: 'rotate(-45deg)'
		},
		':hover': {
			opacity: 0.7
		},
		':active': {
			opacity: 1
		}
	},
	sendButtonDisabled: {
		width: 32,
		height: 32,
		backgroundColor: theme => theme.palette.primary.black,
		opacity: 0.7,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiSvgIcon-root': {
			fontSize: 22,
			color: theme => theme.palette.primary.contrastText,
			transform: 'rotate(-45deg)'
		},
		'& .MuiCircularProgress-root': {
			width: '22px !important',
			height: '22px !important',
			'& .MuiCircularProgress-svg': {
				color: theme => theme.palette.primary.contrastText
			}
		}
	},
	dialogueContainer: {
		width: '100%',
		height: 'calc(100% - 136px)',
		overflowY: 'auto',
		px: 1
	},
	dialogue: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		justifyContent: 'end',
		py: 1,
		userSelect: 'text'
	},
	messageContainer: {
		width: '100%',
		py: 1
	},
	userMessageContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'end',
		py: 1
	},
	messageBox: {
		width: '75%',
		backgroundColor: theme => theme.palette.primary.chat,
		p: 1,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'end',
		justifyContent: 'space-between',
		borderTopLeftRadius: '12px',
		borderTopRightRadius: '12px',
		borderBottomRightRadius: '12px'
	},
	userMessageBox: {
		width: '75%',
		backgroundColor: theme => theme.palette.primary.chat,
		p: 1,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'end',
		justifyContent: 'space-between',
		borderTopLeftRadius: '12px',
		borderTopRightRadius: '12px',
		borderBottomLeftRadius: '12px'
	},
	flex: {
		display: 'flex',
		width: 'calc(100% - 40px)',
		pr: 1
	},
	userBorder: {
		width: 4,
		minWidth: 4,
		backgroundColor: theme => theme.palette.primary.runApiColor,
		mr: 1
	},
	logBorder: {
		width: 4,
		minWidth: 4,
		backgroundColor: theme => theme.palette.primary.saveVariableColor,
		mr: 1
	},
	errorBorder: {
		width: 4,
		minWidth: 4,
		backgroundColor: theme => theme.palette.error.main,
		mr: 1
	},
	assistantBorder: {
		width: 4,
		minWidth: 4,
		backgroundColor: theme => theme.palette.primary.runApiColor,
		mr: 1
	},
	messageText: {
		width: 'calc(100% - 20px)',
		maxWidth: 'calc(100% - 20px)',
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word',
		fontFamily: 'Nunito',
		fontSize: 14,
		lineHeight: '20.5px'
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	},
	collapse: {
		width: '100%',
		minHeight: '100% !important',
		height: '100%',
		'& .MuiCollapse-wrapper': {
			height: '100%',
		}
	},
	collapseContainer: {
		height: '100vh',
		p: 1,
		width: 500,
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRight: theme => `1px solid ${theme.palette.primary.border}`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	collapseTitle: {
		width: '100%',
		px: 1,
		pb: 1,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	collapseBody: {
		width: '100%',
		height: 'calc(100% - 48px)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}

export default TestDialog