import React, { useEffect, useState } from 'react'
import { Box, Link, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material'

import UpsertUserDialog from '../users/upsert-dialog'
import LITERALS from '../../helpers/literals'

const Navbar = ({
	history,
	path,
	toast,
	userId,
	language,
	username,
	name,
	privileges,
	scenarios,
	fetched,
	scenariosList,
	userUpdating,
	userMessage,
	userError,
	fetchProfile,
	fetchScenarios,
	updateUser,
	logout
}) => {
	const [anchorElUser, setAnchorElUser] = useState(null)
	const [initialized, setInitialized] = useState(false)
	const [showUserUpsertDialog, setShowUserUpsertDialog] = useState(false)

	useEffect(() => {
		if (!initialized && !fetched) {
			setInitialized(true)
			fetchProfile(userId)
			fetchScenarios()
		}
	}, [initialized, fetchProfile, fetchScenarios, fetched, userId])

	useEffect(() => {
		if (userMessage) {
			toast(userMessage, { type: 'success' })
			if (userMessage === LITERALS.USER_UPDATED[language])
				setShowUserUpsertDialog(false)
		}
	}, [language, toast, userMessage])

	return <>
		<Box sx={path === '/scenarios/:id' ? styles.main : styles.main2}>
			{path !== '/scenarios/:id' && <Link href='/' sx={styles.link}>
				<img alt='Logo' src={`/static/images/intellectdialog.png`} style={styles.logo} />
			</Link>}

			<Box onClick={e => { setAnchorElUser(e.currentTarget) }} sx={styles.avatar}>
				<Box sx={styles.head} />
				<Box sx={styles.body} />
			</Box>
			<Popover
				keepMounted
				sx={styles.popover}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				anchorEl={anchorElUser}
				open={!!anchorElUser}
				onClose={() => { setAnchorElUser(null) }}
			>
				<Box sx={styles.p2}>
					<Typography color='primary.main' noWrap>{name}</Typography>
				</Box>
				<Box sx={styles.divider} />

				<List sx={styles.paper}>
					<ListItem disablePadding>
						<ListItemButton onClick={() => { setAnchorElUser(null); setShowUserUpsertDialog(true) }}>
							<ListItemText primary={LITERALS.PROFILE[language]} />
						</ListItemButton>
					</ListItem>
					{privileges.includes('users-write') && <ListItem disablePadding>
						<ListItemButton onClick={() => { history.push('/users') }}>
							<ListItemText primary={LITERALS.USERS[language]} />
						</ListItemButton>
					</ListItem>}
					<ListItem disablePadding>
						<ListItemButton onClick={logout}>
							<ListItemText primary={LITERALS.LOGOUT[language]} />
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
		</Box>

		{showUserUpsertDialog && <UpsertUserDialog
			canWrite={privileges.includes('users-write')}
			id={userId}
			language={language}
			username={username}
			name={name}
			userLanguage={language}
			privileges={privileges}
			scenarios={scenarios}
			scenariosList={scenariosList}
			toast={toast}
			onClose={() => { setShowUserUpsertDialog(false) }}
			updating={userUpdating}
			error={userError}
			update={updateUser}
		/>}
	</>
}

const styles = {
	main: {
		position: 'fixed',
		right: 32,
		top: 16,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'end',
		zIndex: 3
	},
	main2: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		pr: 2,
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderBottom: theme => `1px solid ${theme.palette.primary.border}`,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		zIndex: 3
	},
	link: {
		height: 60,
		ml: 2
	},
	logo: {
		height: 60,
		width: 'auto'
	},
	avatar: {
		backgroundColor: theme => theme.palette.primary.avatarBox,
		borderRadius: '50%',
		width: 40,
		height: 40,
		cursor: 'pointer',
		overflow: 'hidden',
		boxShadow: theme => `0px 8px 10px 0px ${theme.palette.primary.black}1A`
	},
	head: {
		width: 16,
		height: 16,
		mt: 1,
		ml: 1.5,
		backgroundColor: theme => theme.palette.primary.contrastText,
		borderRadius: '50%',
		border: theme => `1px solid ${theme.palette.primary.contrastText}`,
		boxShadow: theme => `0px -8px 8px 0px ${theme.palette.primary.contrastText}3D, 0px 4px 4px 0px ${theme.palette.primary.avatar}3D`
	},
	body: {
		width: 32,
		height: 24,
		mt: 0.25,
		ml: 0.5,
		gap: 0,
		background: theme => `radial-gradient(100% 100% at 50% 0%, ${theme.palette.primary.contrastText} 0%, ${theme.palette.primary.contrastText}00 100%)`,
		borderRadius: '50%',
		border: theme => `1px solid ${theme.palette.primary.contrastText}`,
		boxShadow: theme => `0px -8px 8px 0px ${theme.palette.primary.contrastText} inset, 0px 4px 4px 0px ${theme.palette.primary.avatar}3D`
	},
	popover: {
		mt: 1
	},
	paper: {
		width: '100%'
	},
	icon: {
		fontSize: 48,
		mt: 1,
		color: theme => `${theme.palette.primary.avatar}3D`
	},
	divider: {
		borderBottom: theme => `1px solid ${theme.palette.primary.border}`,
	},
	p2: {
		p: 2
	}
}

export default Navbar