import React from 'react'
import { Box, Checkbox, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded } from '@mui/icons-material'

import Dropdown from './dropdown'
import { propTypes } from '../../helpers/constants'
import LITERALS from '../../helpers/literals'

const MappingRuleItem = ({ language, title, divider, nullable, sourcePath, sourceType, destinationPath, valueChanged, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>

		<Box sx={styles.container}>
			<Box sx={styles.checkbox}>
				<Checkbox checked={nullable} onChange={e => valueChanged('nullable', e.target.checked)} />
				<Typography variant='body2'>{LITERALS.NULLABLE[language]}</Typography>
			</Box>

			<TextField
				sx={styles.field}
				placeholder={LITERALS.SOURCE_VALUE[language]}
				value={sourcePath}
				onChange={e => valueChanged('sourcePath', e.target.value)}
			/>

			<Dropdown
				container={styles.dropdown}
				selected={sourceType}
				items={propTypes}
				onChange={item => valueChanged('sourceType', item)}
			/>

			<TextField
				sx={styles.field}
				placeholder={LITERALS.VALUE_TO_SAVE[language]}
				value={destinationPath}
				onChange={e => valueChanged('destinationPath', e.target.value)}
			/>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.icon}/>
			</Box>
		</Box>
	</Box>
)

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	checkbox: {
		width: 96,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	field: {
		width: 'calc(50% - 147px)'
	},
	dropdown: {
		width: 106
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	}
}

export default MappingRuleItem