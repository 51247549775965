import React from 'react'
import { Box, Typography } from '@mui/material'
import { FolderRounded } from '@mui/icons-material'
import AddButton from './add-button'

const NoData = ({ language, title, subTitle, add, color }) => (
	<Box sx={styles.main}>
		<FolderRounded sx={styles.icon} />
		<Box sx={styles.container}>
			<Typography variant='title' fontWeight='bold'>{title[language]}</Typography>
			<Typography variant='body2'>{subTitle[language]}</Typography>
		</Box>
		<AddButton onClick={add} color={color} language={language} />
	</Box>
)

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		p: 2,
		mb: 3
	},
	icon: {
		fontSize: 40,
		color: theme => theme.palette.primary.gray
	},
	container: {
		width: '100%',
		py: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	}
}

export default NoData