import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import Dropdown from '../custom/dropdown'
import NoData from '../custom/no-data'
import TextButton from '../custom/text-button'
import { conditionTypes } from '../../helpers/constants'
import AddButton from '../custom/add-button'
import MappingRuleItem from '../custom/mapping-rule-item'
import LITERALS from '../../helpers/literals'

const ConditionSettings = ({ language, condition, webhooks, toast, onClose, save }) => {
	const [name, setName] = useState('')
	const [webhookId, setWebhookId] = useState('')
	const [conditionWebhookId, setConditionWebhookId] = useState('')
	const [type, setType] = useState('update_auth_header')
	const [mappingRules, setMappingRules] = useState([])
	const theme = useTheme()

	useEffect(() => {
		setName(condition?.name || '')
		setWebhookId(condition?.webhookId || '')
		setConditionWebhookId(condition?.conditionWebhookId || '')
		setType(condition?.type || 'update_auth_header')
		setMappingRules(condition?.mappingRules || [])
	}, [condition])

	const add = () => {
		setMappingRules(mrs => [...mrs, { nullable: false, sourcePath: '', sourceType: 'string', destinationPath: '' }])
	}

	const edit = (idx, key, value) => {
		setMappingRules(mrs => [...mrs.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = idx => {
		setMappingRules(mrs => [...mrs.filter((_, i) => i !== idx)])
	}


	const onSave = useCallback(() => {
		if (!name) {
			toast(LITERALS.PLEASE_ENTER_NAME_3[language], { type: 'error' })
			return
		}

		if (!webhooks.find(webhook => webhook.id === webhookId) || !webhooks.find(webhook => webhook.id === conditionWebhookId)) {
			toast(LITERALS.CHOOSE_WEBHOOK[language], { type: 'error' })
			return
		}

		save({
			name,
			webhookId,
			conditionWebhookId,
			type,
			mappingRules
		})
	}, [conditionWebhookId, language, mappingRules, name, save, toast, type, webhookId, webhooks])

	return <Box sx={styles.main}>
		<DialogTitle
			title={condition?.id ? LITERALS.CONDITION_EDIT[language] : LITERALS.CONDITION_ADD[language]}
			description={LITERALS.CONDITION_MANAGE[language]}
			cancel={onClose}
		/>

		<Box sx={styles.row}>
			<Box sx={styles.w50}>
				<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.NAME_2[language]}</Typography>
				<TextField fullWidth placeholder={`${LITERALS.CONDITION[language]} #1`} value={name} onChange={e => setName(e.target.value)} />
			</Box>
			<Box sx={styles.w50}>
				<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.WEBHOOK[language]}</Typography>
				<Dropdown
					placeholder={LITERALS.WEBHOOK[language]}
					selected={webhookId}
					items={webhooks.map(w => ({ id: w.id, name: w.name }))}
					onChange={item => setWebhookId(item)}
				/>
			</Box>
		</Box>

		<Box sx={styles.row}>
			<Box sx={styles.w50}>
				<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.WEBHOOK_CONDITIONAL[language]}</Typography>
				<Dropdown
					placeholder={LITERALS.WEBHOOK_CONDITIONAL[language]}
					selected={conditionWebhookId}
					items={webhooks.map(w => ({ id: w.id, name: w.name }))}
					onChange={item => setConditionWebhookId(item)}
				/>
			</Box>
			<Box sx={styles.w50}>
				<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.CONDITION_TYPE[language]}</Typography>
				<Dropdown selected={type} items={conditionTypes} onChange={item => setType(item)} />
			</Box>
		</Box>

		<Box sx={styles.divider} />

		<Box sx={styles.middle}>
			<Box sx={styles.items}>
				<TextButton title={LITERALS.VIEW_RULES[language]} active color={theme.palette.primary.apiColor1} onClick={() => { }} />
			</Box>

			<Box sx={styles.body}>
				{
					mappingRules.map((item, key) => (
						<MappingRuleItem
							key={key}
							language={language}
							title={`${LITERALS.RULE[language]} #${key + 1}`}
							divider={key !== 0}
							{...item}
							valueChanged={(k, v) => { edit(key, k, v) }}
							onDelete={() => { remove(key) }}
						/>
					))
				}

				{
					mappingRules.length === 0
						?
						<NoData
							language={language}
							color='apiColor1'
							title={LITERALS.NOTHING_HERE}
							subTitle={LITERALS.ADD_RULE_SET}
							add={add}
						/>
						:
						<AddButton language={language} color='apiColor1' onClick={add} />
				}
			</Box>
		</Box>

		<Box sx={styles.buttonsContainer}>
			<Button variant='contained' color='inherit' sx={styles.button} onClick={onClose}>
				<ArrowBack /> {LITERALS.BACK[language]}
			</Button>
			<Button variant='contained' color='primary' sx={styles.button} onClick={onSave}>{LITERALS.SAVE[language]}</Button>
		</Box>
	</Box>
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		mb: 1.25
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		px: 2,
		mb: 2
	},
	w50: {
		width: 'calc(50% - 8px)'
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	button: {
		width: 'calc(50% - 4px)',
		borderRadius: '100px'
	},
	addButton: {
		width: 120,
		height: 32,
		color: theme => theme.palette.primary.contrastText,
		fontSize: 14,
		lineHeight: '16px',
		borderRadius: '100px',
		boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
		backgroundColor: theme => theme.palette.primary.apiColor1,
		'&:hover': {
			boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
			backgroundColor: theme => `${theme.palette.primary.apiColor1}bf`
		},
		'&:active': {
			boxShadow: theme => `0px 8px 30px 0px ${theme.palette.primary.black}4D`,
			backgroundColor: theme => theme.palette.primary.apiColor1
		},
		my: 5
	},
	schema: {
		width: '100%',
		mt: 1
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 298px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		py: 1,
		px: 2
	}
}

export default ConditionSettings