import axios from 'axios'

import { logout } from './login'

export const ACTION_TYPES = {
	AI_GENERATE_INIT: 'AI_GENERATE_INIT',
	AI_GENERATE_SUCCESS: 'AI_GENERATE_SUCCESS',
	AI_GENERATE_FAIL: 'AI_GENERATE_FAIL',
	AI_CHECK_INIT: 'AI_CHECK_INIT',
	AI_CHECK_SUCCESS: 'AI_CHECK_SUCCESS',
	AI_CHECK_FAIL: 'AI_CHECK_FAIL',
	RESET_MESSAGES: 'RESET_MESSAGES'
}

export const generate = (data, historyPusher) => {
	return async dispatch => {
		dispatch({ type: ACTION_TYPES.AI_GENERATE_INIT })
		try {
			const response = await axios({
				method: 'post',
				url: `/ai/generate`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				data
			})

			dispatch({
				type: ACTION_TYPES.AI_GENERATE_SUCCESS,
				code: response.data.result
			})
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.AI_GENERATE_FAIL, error: err?.response?.data?.message || err.message })
		}
		setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
	}
}

export const check = (data, historyPusher) => {
	return async dispatch => {
		dispatch({ type: ACTION_TYPES.AI_CHECK_INIT })
		try {
			const response = await axios({
				method: 'post',
				url: `/ai/test`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				data
			})

			dispatch({
				type: ACTION_TYPES.AI_CHECK_SUCCESS,
				result: typeof response.data.result === 'string' ?
					JSON.parse(response.data.result.slice(response.data.result.indexOf('{'), response.data.result.lastIndexOf('}') + 1)) || {}
					:
					response.data.result
			})
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.AI_CHECK_FAIL, error: err?.response?.data?.message || err.message })
		}
		setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
	}
}