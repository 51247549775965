import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import CircularBackdrop from '../custom/circular-backdrop'
import TextInput from '../custom/text-input'
import TextButton from '../custom/text-button'
import Dropdown from '../custom/dropdown'
import { languages, privilegesList } from '../../helpers/constants'
import LITERALS from '../../helpers/literals'

const UpsertDialog = ({
	canWrite,
	id,
	language,
	username,
	name,
	userLanguage,
	privileges,
	scenarios,
	scenariosList,
	toast,
	onClose,
	updating,
	error,
	update
}) => {
	const [initialized, setInitialized] = useState(false)
	const [settings, setSettings] = useState({})
	const [stepError, setStepError] = useState('')
	const theme = useTheme()

	const checkStep = useCallback(() => {
		if (settings?.name?.length < 3) {
			setStepError(LITERALS.PLEASE_ENTER_NAME[language])
			return
		}
		if (settings?.username?.length < 3) {
			setStepError(LITERALS.PLEASE_ENTER_USERNAME[language])
			return
		}

		setStepError('')
	}, [language, settings])

	useEffect(() => {
		if (!initialized) {
			setSettings({ id, language: userLanguage, name, username, privileges, scenarios })
			setInitialized(true)
		}
		checkStep()
	}, [id, userLanguage, name, username, privileges, scenarios, initialized, checkStep])

	useEffect(() => {
		if (!updating && error)
			toast(error, { type: 'error' })
	}, [error, toast, updating])

	const addPrivilege = useCallback(item => {
		setSettings(s => ({ ...s, privileges: [...s.privileges.filter(p => item !== p), item] }))
	}, [])

	const removePrivilege = useCallback(item => {
		setSettings(s => ({ ...s, privileges: [...s.privileges.filter(p => item !== p)] }))
	}, [])

	const addScenario = useCallback((id, aType, rType) => {
		setSettings(s => ({ ...s, scenarios: [...s.scenarios.filter(p => p !== `${id}-${aType}` && p !== `${id}-${rType}`), `${id}-${aType}`] }))
	}, [])

	const removeScenario = useCallback((id, rType) => {
		setSettings(s => ({ ...s, scenarios: [...s.scenarios.filter(p => p !== `${id}-${rType}`)] }))
	}, [])

	return <Dialog open={true} maxWidth='md' fullWidth={true}>
		<DialogTitle sx={styles.title}>
			<Typography variant='title' fontWeight='bold'>
				{id === '-1' ? LITERALS.ADD_USER[language] : LITERALS.UPDATE_USER[language]}
			</Typography>
			<IconButton aria-label='close' onClick={onClose} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers>
			<Box sx={styles.box}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.PERSONAL_INFO[language]} active color={theme.palette.primary.main} onClick={() => { }} />
				</Box>

				<Box sx={styles.formContainer}>
					<Box sx={styles.w45}>
						<TextInput
							label={LITERALS.NAME[language]}
							placeholder={LITERALS.ENTER_NAME[language]}
							value={settings.name || ''}
							onChange={e => { setSettings(s => ({ ...s, name: e.target.value })) }}
						/>
					</Box>
					<Box sx={styles.w45}>
						<Typography variant='body2' color='text.primary' fontWeight='bold'>{LITERALS.INTERFACE_LANGUAGE[language]}</Typography>
						<Dropdown
							selected={settings.language || 'ru'}
							items={languages}
							onChange={lang => setSettings(s => ({ ...s, language: lang })) }
						/>
					</Box>
				</Box>
				<Box sx={styles.formContainer}>
					<Box sx={styles.w45}>
						<TextInput
							label={LITERALS.USERNAME[language]}
							placeholder={LITERALS.ENTER_USERNAME[language]}
							value={settings.username || ''}
							onChange={e => { setSettings(s => ({ ...s, username: e.target.value })) }}
						/>
					</Box>
					<Box sx={styles.w45}>
						<TextInput
							label={LITERALS.PASSWORD[language]}
							placeholder={LITERALS.ENTER_PASSWORD[language]}
							value={settings.password || ''}
							onChange={e => { setSettings(s => ({ ...s, password: e.target.value })) }}
						/>
					</Box>
				</Box>
			</Box>

			{canWrite && <Box sx={styles.box}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.PRIVILEGES[language]} active color={theme.palette.primary.main} onClick={() => { }} />
				</Box>

				<Box sx={styles.row}>
					{
						privilegesList.map((privilege, key) => <Box key={key} sx={styles.halfRow}>
							<Switch
								checked={!!settings?.privileges?.includes(privilege.tag)}
								onChange={e => {
									if (e.target.checked)
										addPrivilege(privilege.tag)
									else
										removePrivilege(privilege.tag)
								}}
							/>
							<Typography>{privilege.title[language]}</Typography>
						</Box>)
					}
				</Box>
			</Box>}

			{canWrite && scenariosList.length > 0 && <Box sx={styles.box}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.SCENARIOS[language]} active color={theme.palette.primary.main} onClick={() => { }} />
				</Box>
				<Box sx={styles.scenarios}>
					{
						scenariosList.map((scenario, key) => <Box sx={styles.scenarioRow} key={key}>
							<Box sx={styles.name}>
								<Typography fontWeight='bold' noWrap>{scenario.name}</Typography>
								<Typography variant='body3' color='primary.disabled' noWrap>{scenario.description || '---'}</Typography>
							</Box>

							<Box sx={styles.item1}>
								<Switch
									checked={!!settings?.scenarios?.includes(`${scenario.id}-v`)}
									onChange={e => {
										if (e.target.checked)
											addScenario(scenario.id, 'v', 'e')
										else
											removeScenario(scenario.id, 'v')
									}}
								/>
								<Typography>{LITERALS.VIEW[language]}</Typography>
							</Box>

							<Box sx={styles.item2}>
								<Switch
									checked={!!settings?.scenarios?.includes(`${scenario.id}-e`)}
									onChange={e => {
										if (e.target.checked)
											addScenario(scenario.id, 'e', 'v')
										else
											removeScenario(scenario.id, 'e')
									}}
								/>
								<Typography>{LITERALS.VIEW_EDIT[language]}</Typography>
							</Box>
						</Box>)
					}
				</Box>
			</Box>}

			<CircularBackdrop open={updating} position='absolute' />
		</DialogContent>
		<DialogActions sx={styles.p2}>
			<Tooltip title={stepError} arrow placement='left-start'>
				<span>
					<Button
						sx={styles.button}
						disabled={!!stepError || updating}
						variant='contained'
						onClick={() => { update(settings) }}
					>
						{settings.id !== '-1' ? LITERALS.UPDATE[language] : LITERALS.CREATE[language]}
					</Button>
				</span>
			</Tooltip>
		</DialogActions>
	</Dialog>
}

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	box: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		pb: 3
	},
	items: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 2,
		overflowX: 'auto'
	},
	scenarios: {
		width: '100%',
		display: 'flex',
		px: 3,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 2
	},
	w100: {
		width: '100%'
	},
	w45: {
		width: '45%'
	},
	formContainer: {
		px: 3,
		mb: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		px: 3
	},
	halfRow: {
		width: '45%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	scenarioRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		mb: 2,
		px: 2.5,
		py: 1.5,
		border: theme => `1px solid ${theme.palette.primary.border}`,
		borderRadius: '10px'
	},
	name: {
		width: 'calc(100% - 450px)'
	},
	p2: {
		p: 2
	},
	item1: {
		width: 200,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end'
	},
	item2: {
		width: 250,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end'
	}
}

export default UpsertDialog