import { ACTION_TYPES } from '../actions/users'
import { ACTION_TYPES as ACTION_TYPES_LOGIN } from '../actions/login'

const initialState = {
	users: [],
	fetching: false,
	fetched: false,
	updating: false,
	creating: false,
	deleting: [],
	message: '',
	error: ''
}

const users = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_USERS_INIT:
			return {
				...state,
				users: [],
				fetching: true,
				fetched: false,
				message: '',
				error: ''
			}
		case ACTION_TYPES.FETCH_USERS_SUCCESS:
			return {
				...state,
				users: action.data,
				fetching: false,
				fetched: true
			}
		case ACTION_TYPES.FETCH_USERS_FAIL:
			return {
				...state,
				error: action.error,
				fetching: false,
				fetched: false
			}
		case ACTION_TYPES.CREATE_USER_INIT:
			return {
				...state,
				creating: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.CREATE_USER_SUCCESS:
			return {
				...state,
				users: [{ ...action.data }, ...state.users],
				message: action.message,
				creating: false
			}
		case ACTION_TYPES.CREATE_USER_FAIL:
			return {
				...state,
				error: action.error,
				creating: false
			}
		case ACTION_TYPES.UPDATE_USER_INIT:
			return {
				...state,
				updating: true,
				message: '',
				error: ''
			}
		case ACTION_TYPES.UPDATE_USER_SUCCESS:
			return {
				...state,
				users: [...state.users.map(user => user.id === action.data.id ? { ...action.data } : user)],
				updating: false,
				message: action.message,
				error: ''
			}
		case ACTION_TYPES.UPDATE_USER_FAIL:
			return {
				...state,
				updating: false,
				error: action.error
			}
		case ACTION_TYPES.DELETE_USER_INIT:
			return {
				...state,
				deleting: [...state.deleting, action.id],
				message: '',
				error: ''
			}
		case ACTION_TYPES.DELETE_USER_SUCCESS:
			return {
				...state,
				deleting: [...state.deleting.filter(del => del !== action.id)],
				users: [...state.users.filter(user => user.id !== action.id)],
				message: action.message
			}
		case ACTION_TYPES.DELETE_USER_FAIL:
			return {
				...state,
				deleting: [...state.deleting.filter(del => del !== action.id)],
				error: action.error
			}
		case ACTION_TYPES.RESET_MESSAGES:
			return {
				...state,
				message: '',
				error: ''
			}
		case ACTION_TYPES_LOGIN.LOGOUT:
			return {
				...state,
				...initialState
			}
		default:
			return state
	}
}

export default users