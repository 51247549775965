import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextButton from '../custom/text-button'
import NoData from '../custom/no-data'
import AddButton from '../custom/add-button'
import ParameterItem from '../custom/parameter-item'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'

const CheckParamsNodeSettings = ({ language, toast, node, start, update, cancel }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [params, setParams] = useState([])
	const [savedParams, setSavedParams] = useState([])
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setParams(node?.data?.params || [])
			setId(node?.id)
		}
	}, [id, node])

	useEffect(() => {
		const result = []
		start?.data?.funcs?.forEach(func => {
			func?.properties?.forEach(prop => {
				if (!result.includes(prop.name))
					result.push(prop.name)
			})
		})

		setSavedParams(result)
	}, [start])


	const add = () => {
		setParams(prms => [...prms, { name: '', value: '' }])
	}

	const edit = (idx, key, value) => {
		setParams(prms => [...prms.map((p, i) => ({ ...p, [key]: i === idx ? value : p[key] }))])
	}

	const remove = idx => {
		setParams(prms => [...prms.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		for (const param of params) {
			if (!param.name) {
				toast(LITERALS.PLEASE_ENTER_PARAMETER[language], { type: 'error' })
				return
			}

			if (!param.value) {
				toast(LITERALS.PLEASE_ENTER_PARAMETER_TEXT[language], { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, params } })
	}, [description, language, node, params, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.PARAMETERS_2[language]} active color={theme.palette.primary.checkParamsColor} onClick={() => { }} />
				</Box>
				<Box sx={styles.body}>
					{
						params.map((item, key) => (
							<ParameterItem
								key={key}
								language={language}
								title={`${LITERALS.PARAMETER[language]} #${key + 1}`}
								divider={key !== 0}
								params={savedParams}
								{...item}
								valueChanged={(k, v) => { edit(key, k, v) }}
								onDelete={() => { remove(key) }}
							/>
						))
					}
					{
						params.length === 0
							?
							<NoData
								language={language}
								color='checkParamsColor'
								title={LITERALS.NOTHING_HERE}
								subTitle={LITERALS.ADD_VARIABLE_SET}
								add={add}
							/>
							:
							<AddButton language={language} color='checkParamsColor' onClick={add} />
					}
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
		mb: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 239px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		px: 2,
		py: 1
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default CheckParamsNodeSettings