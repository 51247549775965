import { ACTION_TYPES } from '../actions/ai'

const initialState = {
	code: '',
	optimizedCode: '',
	generating: false,
	checking: false,
	error: '',
	message: ''
}

const ai = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.AI_GENERATE_INIT:
			return {
				...state,
				code: '',
				generating: true,
				error: ''
			}
		case ACTION_TYPES.AI_GENERATE_SUCCESS:
			return {
				...state,
				code: action.code,
				generating: false
			}
		case ACTION_TYPES.AI_GENERATE_FAIL:
			return {
				...state,
				error: action.error,
				generating: false
			}
		case ACTION_TYPES.AI_CHECK_INIT:
			return {
				...state,
				optimizedCode: '',
				checking: true,
				error: '',
				message: ''
			}
		case ACTION_TYPES.AI_CHECK_SUCCESS:
			return {
				...state,
				optimizedCode: action.result?.code || '',
				checking: false,
				error: action.result?.correct ? '' : (action.result?.comment || ''),
				message: action.result?.correct ? (action.result?.comment || '') : ''
			}
		case ACTION_TYPES.AI_CHECK_FAIL:
			return {
				...state,
				error: action.error,
				generating: false
			}
		case ACTION_TYPES.RESET_MESSAGES:
			return {
				...state,
				error: '',
				message: ''
			}
		default:
			return state
	}
}

export default ai