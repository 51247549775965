import {
	AccountTreeRounded,
	AutoAwesome,
	FactCheck,
	Public,
	RocketLaunch,
	Save,
	Storage,
	SwapCalls,
	Tune,
	Webhook
} from '@mui/icons-material'
import { Position } from '@xyflow/react'
import LITERALS from './literals'

export const components = [
	{
		title: LITERALS.SCENARIOS,
		items: [
			{
				title: LITERALS.START,
				Icon: RocketLaunch,
				color: '#7A5AF8',
				type: 'start',
				help: LITERALS.START_DESCRIPTION
			},
			{
				title: LITERALS.API_REQUEST,
				Icon: Webhook,
				color: '#EE46BC',
				type: 'api',
				help: LITERALS.API_REQUEST_DESCRIPTION
			}
		]
	},
	{
		title: LITERALS.COMMANDS,
		items: [
			{ title: LITERALS.CHECK_PARAMS, Icon: FactCheck, color: '#525866', type: 'checkParams', help: LITERALS.CHECK_PARAMS_DESCRIPTION },
			{ title: LITERALS.API_RUN, Icon: Public, color: '#525866', type: 'runScenario', help: LITERALS.API_RUN_DESCRIPTION },
			{ title: LITERALS.CONDITIONS, Icon: SwapCalls, color: '#525866', type: 'checkConditions', help: LITERALS.CONDITIONS_DESCRIPTION },
			{ title: LITERALS.MAPPER, Icon: AccountTreeRounded, color: '#525866', type: 'mapper', help: LITERALS.MAPPER_DESCRIPTION },
			{ title: LITERALS.FILTER, Icon: Tune, color: '#525866', type: 'filter', help: LITERALS.FILTER_DESCRIPTION },
			{ title: LITERALS.SAVE_VARIABLES, Icon: Save, color: '#525866', type: 'save_variables', help: LITERALS.SAVE_VARIABLES_DESCRIPTION },
			{ title: LITERALS.RESULT, Icon: Storage, color: '#525866', type: 'getResult', help: LITERALS.RESULT_DESCRIPTION },
			{ title: LITERALS.AI_REQUEST, Icon: AutoAwesome, color: '#525866', type: 'chatAI', help: LITERALS.AI_REQUEST_DESCRIPTION }
		]
	}
]

export const roles = [
	{ id: 'system', name: LITERALS.PROMPT_SYSTEM },
	{ id: 'user', name: LITERALS.PROMPT_USER },
	{ id: 'assistant', name: LITERALS.PROMPT_ASSISTANT }
]

export const propTypes = [
	{ id: 'string', name: 'String' },
	{ id: 'number', name: 'Number' },
	{ id: 'array', name: 'Array' },
	{ id: 'object', name: 'Object' }
]

export const methods = [
	{ id: 'GET', name: 'GET' },
	{ id: 'POST', name: 'POST' },
	{ id: 'PUT', name: 'PUT' },
	{ id: 'DELETE', name: 'DELETE' }
]

export const contentTypes = [
	{ id: 'application/json', name: 'application/json' },
	{ id: 'application/x-www-form-urlencoded', name: 'application/x-www-form-urlencoded' }
]

export const requiredEntryTypes = [
	{ id: 'headers', name: LITERALS.HEADERS },
	{ id: 'cookies', name: LITERALS.COOKIES },
	{ id: 'body', name: LITERALS.BODY },
	{ id: 'queryParams', name: LITERALS.PARAMETERS }
]

export const conditionTypes = [
	{ id: 'update_auth_header', name: 'update_auth_header' },
	{ id: 'update_auth_query', name: 'update_auth_query' },
	{ id: 'header', name: 'header' }
]

export const inputHandles = [
	{ id: 'target-1', type: 'target', position: Position.Top },
	{ id: 'target-2', type: 'target', position: Position.Left }
]

export const outputHandles = [
	{ id: 'source-1', type: 'source', position: Position.Right },
	{ id: 'source-2', type: 'source', position: Position.Bottom }
]

export const componentsInfo = {
	start: { title: LITERALS.START, description: LITERALS.START_DESCRIPTION_SHORT, width: 600, height: 720 },
	api: { title: LITERALS.API_SCENARIO, description: LITERALS.API_SCENARIO_DESCRIPTION, width: 600, height: 720 },
	checkParams: { title: LITERALS.CHECK_PARAMS_2, description: LITERALS.CHECK_PARAMS_2_DESCRIPTION, width: 600, height: 720 },
	runScenario: { title: LITERALS.API_SCENARIO_RUN, description: LITERALS.API_SCENARIO_RUN_DESCRIPTION, width: 500, height: 380 },
	checkConditions: { title: LITERALS.CHECK_CONDITIONS, description: LITERALS.CHECK_CONDITIONS_DESCRIPTION, width: 700, height: 720 },
	mapper: { title: LITERALS.MAPPER, description: LITERALS.MAPPER_DESCRIPTION_2, width: 600, height: 720 },
	filter: { title: LITERALS.FILTER, description: LITERALS.FILTER_DESCRIPTION_2, width: 500, height: 550 },
	save_variables: { title: LITERALS.SAVE_VARIABLES, description: LITERALS.SAVE_VARIABLES_DESCRIPTION_2, width: 600, height: 720 },
	getResult: { title: LITERALS.RESULT, description: LITERALS.RESULT_DESCRIPTION_2, width: 500, height: 465 },
	chatAI: { title: LITERALS.AI_REQUEST, description: LITERALS.AI_REQUEST_DESCRIPTION, width: 500, height: 550 }
}

export const formats = [
	{ id: '', name: LITERALS.NO_FORMAT },
	{ id: 'date', name: LITERALS.DATE }
]

export const privilegesList = [
	{
		title: LITERALS.CREATE_UPDATE_USERS,
		tag: 'users-write'
	},
	{
		title: LITERALS.CREATE_SCENARIOS,
		tag: 'scenarios-write'
	}
]

export const languages = [
	{ id: 'ru', name: 'Русский' },
	{ id: 'az', name: 'Azərbaycan dili' },
	{ id: 'de', name: 'Deutsch' },
	{ id: 'en', name: 'English' },
	{ id: 'es', name: 'Español' },
	{ id: 'ka', name: 'ქართული' },
	{ id: 'kk', name: 'Қазақша' },
	{ id: 'pt', name: 'Português' },
	{ id: 'tr', name: 'Türkçe' },
	{ id: 'uz', name: 'Oʻzbekcha' },
]