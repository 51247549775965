import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import CircularBackdrop from '../custom/circular-backdrop'

const DeleteDialog = ({ title, question, yesText, cancel, deleting, remove }) => (
	<Dialog open={true} maxWidth='xs' fullWidth={true}>
		<DialogTitle sx={styles.title}>
			<Typography variant='title' fontWeight='bold'>{title}</Typography>
			<IconButton aria-label='close' onClick={cancel} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers>
			<Typography sx={styles.text} textAlign='center'>{question}</Typography>
			<CircularBackdrop open={deleting} position='absolute' />
		</DialogContent>
		<DialogActions sx={styles.p2}>
			<Button sx={styles.button} disabled={deleting} variant='contained' onClick={remove}>{yesText}</Button>
		</DialogActions>
	</Dialog>
)

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	text: {
		width: '100%',
		p: 1
	},
	p2: {
		p: 2
	}
}

export default DeleteDialog