import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import NodeTitle from '../custom/node-title'
import NodeButton from '../custom/node-button'
import NodePort from '../custom/node-port'
import LITERALS from '../../helpers/literals'

const StartNode = ({ data }) => {
	const theme = useTheme()

	const styles = useCallback(() => {
		return {
			main: {
				width: 240,
				minHeight: 80,
				borderRadius: '16px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxShadow: `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
				p: 1,
				opacity: data.focused ? 1 : 0.3,
				border: `2px solid ${data.logged ? theme.palette.success.main : `${theme.palette.primary.startColor1}33`}`,
				background: data.logged
					?
					`${theme.palette.success.main}33`
					:
					`linear-gradient(180deg, ${theme.palette.primary.startColor2} 0%, ${theme.palette.primary.startColor3} 100%)`
			},
			mainInError: {
				width: 240,
				minHeight: 80,
				borderRadius: '16px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				p: 1,
				opacity: data.focused ? 1 : 0.3,
				border: `2px solid ${theme.palette.primary.border}`,
				background: `${theme.palette.error.main}33`,
				animation: 'blink 1s',
				animationIterationCount: 'infinite',
				'@keyframes blink ': {
					'50%': {
						background: `${theme.palette.error.main}99`,
						borderColor: theme => theme.palette.error.main
					}
				}
			},
			container: {
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: 44 * data.funcs.length - 4
			}
		}
	}, [data.focused, data.funcs?.length, data.logged, theme])

	return <Box sx={data.inError ? styles().mainInError : styles().main}>
		<NodeTitle title={LITERALS.START[data.language]} color='primary.startColor1' />

		{data?.funcs?.length > 0 && (
			<Box sx={styles().container}>
				{data.funcs.map((func, key) => (
					<NodePort
						key={key}
						idx={key}
						title={func.name}
						id={func.id}
						color={theme.palette.primary.startColor1}
						handleColor={theme.palette.primary.startColor1}
					/>
				))}
			</Box>
		)}
		<NodeButton
			innerColor={theme.palette.primary.contrastText}
			outerColor={theme.palette.primary.startColor1}
		/>
	</Box>
}

export default StartNode