import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import { componentsInfo } from '../../helpers/constants'
import DialogTitle from '../custom/dialog-title'
import Tabs from '../custom/tabs'
import NoData from '../custom/no-data'
import PromtItem from '../custom/promt-item'
import ListItem from '../custom/list-item'
import AddButton from '../custom/add-button'
import FunctionSettings from './function-settings'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'

const tabs = [LITERALS.PROMPTS, LITERALS.FUNCTIONS]

const StartNodeSettings = ({ language, toast, node, update, cancel }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [history, setHistory] = useState(2048)
	const [promts, setPromts] = useState([])
	const [funcs, setFuncs] = useState([])
	const [status, setStatus] = useState({})
	const [tab, setTab] = useState(0)

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setHistory(node?.data?.history || 2048)
			setPromts(node?.data?.promts || [])
			setFuncs(node?.data?.funcs || [])
			setId(node?.id)
		}
	}, [id, node])

	const onHistoryChanged = e => {
		const value = parseInt(e.target.value)
		if (!e.target.value)
			setHistory(e.target.value)
		else if (!isNaN(value) && value < 25000)
			setHistory(value)
	}

	const addPromt = () => {
		setPromts(pts => [...pts, { role: 'system', content: '' }])
	}

	const editPromt = (idx, key, value) => {
		setPromts(pts => [...pts.map((p, i) => ({ ...p, [key]: i === idx ? value : p[key] }))])
	}

	const deletePromt = idx => {
		setPromts(pts => [...pts.filter((_, i) => i !== idx)])
	}

	const remove = (type, idx) => {
		if (type === 'function') {
			setFuncs(fns => [...fns.filter((_, i) => i !== idx)])
		}
	}

	const onFunctionSave = useCallback(func => {
		if (status.action === 'function') {
			if (status.type === 'add')
				setFuncs(fns => [...fns, { id: uuidv4(), ...func }])
			else if (status.type === 'edit')
				setFuncs(fns => [...fns.map((fn, key) => key === status.idx ? ({ ...fn, ...func }) : fn)])

			setStatus({})
		}
	}, [status])

	const save = useCallback(() => {
		for (const promt of promts) {
			if (promt.content.length === 0) {
				toast(LITERALS.PROMPT_CANNOT_BE_EMPTY[language], { type: 'error' })
				return
			}
		}

		if (history < 1) {
			toast(LITERALS.SYMBOL_COUNT_ERROR[language], { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, description, history, promts, funcs } })
	}, [description, funcs, history, language, node, promts, toast, update])

	return (
		<Box sx={styles.main}>
			{
				!status.action && <>
					<DialogTitle
						title={componentsInfo[node.type].title[language]}
						description={componentsInfo[node.type].description[language]}
						cancel={cancel}
					/>

					<Box sx={styles.row}>
						<TextArea
							placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
							rows={3}
							value={description}
							onChange={e => setDescription(e.target.value)}
						/>
					</Box>

					<Box sx={styles.row2}>
						<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.SYMBOL_COUNT[language]}</Typography>
						<TextField
							fullWidth
							placeholder={`${LITERALS.EXAMPLE[language]}: 2048'`}
							value={history}
							onChange={onHistoryChanged}
						/>
					</Box>

					<Box sx={styles.divider} />

					<Box sx={styles.tabs}>
						<Tabs language={language} tabs={tabs} width={componentsInfo[node.type].width - 32} value={tab} setValue={setTab} />
					</Box>

					<Box sx={styles.body}>
						{
							((tab === 0 && promts.length === 0) || (tab === 1 && funcs.length === 0))
								?
								<NoData
									language={language}
									color='startColor1'
									title={LITERALS.NOTHING_HERE}
									subTitle={tab === 0 ? LITERALS.ADD_PROMPT_SET : LITERALS.ADD_FUNCTION_SET}
									add={() => {
										if (tab === 0)
											addPromt()
										else if (tab === 1)
											setStatus({ action: 'function', type: 'add' })
									}}
								/>
								:
								<>
									{tab === 0
										?
										promts.map((item, key) => (
											<PromtItem
												key={key}
												divider={key !== 0}
												title={`${LITERALS.PROMPT_SYSTEM[language]} #${key + 1}`}
												{...item}
												valueChanged={(k, v) => { editPromt(key, k, v) }}
												onDelete={() => { deletePromt(key) }}
											/>
										))
										:
										funcs.map((func, key) => (
											<ListItem
												key={key}
												divider={key !== 0}
												title={`${func.name}: ${func.description}`}
												onEdit={() => { setStatus({ action: 'function', type: 'edit', idx: key }) }}
												onDelete={() => { remove('function', key) }}
											/>
										))
									}

									<AddButton
										language={language}
										color='startColor1'
										onClick={() => {
											if (tab === 0)
												addPromt()
											else if (tab === 1)
												setStatus({ action: 'function', type: 'add' })
										}}
									/>
								</>
						}
					</Box>

					<Box sx={styles.buttonsContainer}>
						<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
					</Box>
				</>
			}

			{status.action === 'function' && (
				<FunctionSettings
					language={language}
					func={status.type === 'add' ? null : funcs[status.idx]}
					toast={toast}
					onClose={() => { setStatus({}) }}
					save={onFunctionSave}
				/>
			)}
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2
	},
	row2: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		px: 2
	},
	title: {
		my: 1.25
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		my: 2
	},
	tabs: {
		width: '100%',
		px: 2
	},
	body: {
		height: 'calc(100% - 292px)',
		overflowY: 'auto',
		px: 2,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default StartNodeSettings