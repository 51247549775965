import Dataflow from './containers/dataflow'
import Scenarios from './containers/scenarios'
import Users from './containers/users'
import Login from './containers/login'

const routes = [
	{
		exact: true,
		path: '/',
		Component: Scenarios,
		protected: true
	},
	{
		exact: true,
		path: '/scenarios/:id',
		Component: Dataflow,
		protected: true
	},
	{
		exact: true,
		path: '/login',
		Component: Login
	},
	{
		exact: true,
		path: '/users',
		Component: Users,
		protected: true
	},
	{
		path: '*',
		Component: Scenarios
	}
]

export default routes