import axios from 'axios'

export const ACTION_TYPES = {
	LOGIN_INIT: 'LOGIN_INIT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILED: 'LOGIN_FAILED',
	LOGOUT: 'LOGOUT'
}

export const logout = historyPusher => {
	return async (dispatch) => {
		try {
			dispatch({ type: ACTION_TYPES.LOGOUT })
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')
			localStorage.removeItem('tokenExpiresAt')
			historyPusher('/login')
		} catch { }
	}
}

export const login = (data, historyPusher) => {
	return async (dispatch) => {
		try {
			dispatch({ type: ACTION_TYPES.LOGIN_INIT })
			const response = await axios({ method: 'post', url: '/app/login', data })
			localStorage.setItem('accessToken', response.data.token)
			localStorage.setItem('refreshToken', response.data.refreshToken)
			localStorage.setItem('tokenExpiresAt', response.data.expiresAt)
			dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, id: response.data.userId })
            historyPusher('/')
		} catch (err) {
			dispatch({ type: ACTION_TYPES.LOGIN_FAILED, error: err?.response?.data?.message || err.message })
		}
	}
}