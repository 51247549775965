import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import CircularBackdrop from '../custom/circular-backdrop'
import LITERALS from '../../helpers/literals'
import TextArea from './text-area'
import TooltipTitle from './tooltip-title'

const CheckCodeDialog = ({ language, type, code, setCode, optimized, checking, cancel, check, apply }) => (
	<Dialog open={true} maxWidth='md' fullWidth={true}>
		<DialogTitle sx={styles.title}>
			<Typography variant='title' fontWeight='bold'>{LITERALS.CHECK_CODE[language]}</Typography>
			<IconButton aria-label='close' onClick={cancel} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers>
			<Box sx={styles.body}>
				<Box sx={styles.w100}>
					<Typography fontWeight='bold' variant='h6' sx={styles.text2} noWrap>{LITERALS.CODE[language]}</Typography>
					<TextArea rows={6} value={code} onChange={e => setCode(e.target.value)} />
				</Box>
				<Box sx={styles.w100}>
					<Typography fontWeight='bold' variant='h6' sx={styles.text2} noWrap>{LITERALS.OPTIMIZED_CODE[language]}</Typography>
					<TextArea rows={5} value={optimized} onChange={() => { }} />
				</Box>
			</Box>
			<CircularBackdrop open={checking} position='absolute' />
		</DialogContent>
		<DialogActions sx={styles.p2}>
			{
				!code
					?
					<Tooltip title={<TooltipTitle title={LITERALS.PLEASE_ENTER_CODE[language]} />} arrow>
						<span><Button disabled variant='contained' onClick={() => { }}>{LITERALS.CHECK[language]}</Button></span>
					</Tooltip>
					:
					<Button disabled={checking} variant='contained' onClick={() => { check({ type, code }) }}>{LITERALS.CHECK[language]}</Button>
			}
			<Button disabled={!optimized} variant='contained' onClick={apply}>{LITERALS.APPLY_CODE[language]}</Button>
		</DialogActions>
	</Dialog>
)

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	text: {
		width: '100%',
		p: 1
	},
	text2: {
		my: 1.25,
		mr: 2
	},
	p2: {
		p: 2
	},
	body: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	w100: {
		width: '100%'
	}
}

export default CheckCodeDialog