import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { login } from '../../actions/login'
import Login from '../../components/login'

const mapStateToProps = state => {
	return {
		language: state.profile.language,
		logging: state.profile.logging,
		error: state.profile.error
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		login: data => dispatch(login(data, ownProps.history.push))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))