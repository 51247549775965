import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Draggable from 'react-draggable'
import { useReactFlow, useViewport } from '@xyflow/react'

import StartNodeSettings from '../nodes/start-settings'
import ApiNodeSettings from '../nodes/api-settings'
import CheckParamsNodeSettings from '../nodes/check-params-settings'
import RunAPINodeSettings from '../nodes/run-api-settings'
import CheckConditionsNodeSettings from '../nodes/check-conditions-settings'
import MapperNodeSettings from '../nodes/mapper-settings'
import FilterNodeSettings from '../nodes/filter-settings'
import SaveVariablesNodeSettings from '../nodes/save-variables-settings'
import ResultNodeSettings from '../nodes/result-settings'
import ChatAINodeSettings from '../nodes/chat-ai-settings'
import { componentsInfo } from '../../helpers/constants'

const Settings = ({ language, toast, open, node, nodes, update, cancel, code, generating, generate, optimizedCode, checking, check }) => {
	const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight })
	const [options, setOptions] = useState({ language, toast, update, cancel })
	const { flowToScreenPosition } = useReactFlow()
	const { zoom } = useViewport()

	useEffect(() => {
		const handleWindowResize = () => {
			setSize({ width: window.innerWidth, height: window.innerHeight })
		}

		window.addEventListener('resize', handleWindowResize)
		return () => { window.removeEventListener('resize', handleWindowResize) }
	}, [])

	useEffect(() => {
		setOptions({ language, toast, update, cancel })
	}, [cancel, language, toast, update])

	const defaultPosition = useCallback(() => {
		if (!node) return { x: 0, y: 0 }

		const nodeWidth = node.measured.width * zoom
		const nodeHeight = node.measured.height * zoom
		const { x: nodeX, y: nodeY } = flowToScreenPosition(node.position)
		const popupWidth = componentsInfo[node.type].width
		const popupHeight = componentsInfo[node.type].height

		let popupX = nodeX + nodeWidth + 20
		let popupY = nodeY

		if (popupX + popupWidth > size.width) {
			popupX = nodeX - popupWidth - 20
			if (popupX < 0)
				popupX = (size.width - popupWidth) / 2
		}

		if (popupY + popupHeight > size.height) {
			popupY = nodeY - popupHeight + nodeHeight
			if (popupY < 0)
				popupY = (size.height - popupHeight) / 2
		}

		return { x: popupX, y: popupY }
	}, [flowToScreenPosition, node, size, zoom])

	return <>
		{open && !!node && <Box sx={styles.main}>
			<Draggable defaultPosition={defaultPosition()} handle='div.drag-handle'>
				<Box sx={{ ...styles.container, width: componentsInfo[node.type].width, height: componentsInfo[node.type].height }}>
					{node.type === 'start' && (<StartNodeSettings {...options} node={node} />)}
					{node.type === 'api' && (<ApiNodeSettings {...options} node={node} />)}

					{node.type === 'checkParams' && (<CheckParamsNodeSettings {...options} node={node} start={nodes.find(nd => nd.type === 'start')} />)}
					{node.type === 'runScenario' && (<RunAPINodeSettings {...options} node={node} api={nodes.filter(nd => nd.type === 'api')} />)}
					{node.type === 'checkConditions' && (
						<CheckConditionsNodeSettings
							{...options}
							node={node}
							code={code}
							generating={generating}
							generate={generate}
							optimizedCode={optimizedCode}
							checking={checking}
							check={check}
						/>
					)}
					{node.type === 'mapper' && (<MapperNodeSettings {...options} node={node} />)}
					{node.type === 'filter' && (
						<FilterNodeSettings
							{...options}
							node={node}
							code={code}
							generating={generating}
							generate={generate}
							optimizedCode={optimizedCode}
							checking={checking}
							check={check}
						/>
					)}
					{node.type === 'save_variables' && (<SaveVariablesNodeSettings {...options} node={node} />)}
					{node.type === 'getResult' && (
						<ResultNodeSettings
							{...options}
							node={node}
							code={code}
							generating={generating}
							generate={generate}
							optimizedCode={optimizedCode}
							checking={checking}
							check={check}
						/>
					)}
					{node.type === 'chatAI' && (
						<ChatAINodeSettings
							{...options}
							node={node}
							code={code}
							generating={generating}
							generate={generate}
							optimizedCode={optimizedCode}
							checking={checking}
							check={check}
						/>
					)}
				</Box>
			</Draggable>
		</Box>}
	</>
}

const styles = {
	main: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 1005
	},
	container: {
		background: theme => theme.palette.primary.contrastText,
		borderRadius: '24px',
		border: theme => `1px solid ${theme.palette.primary.divider}`,
		boxShadow: theme => `0px 4px 50px 0px ${theme.palette.primary.black}33`,
		display: 'flex',
		flexDirection: 'column'
	}
}

export default Settings