import React, { useCallback, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import CircularBackdrop from '../custom/circular-backdrop'
import TextInput from '../custom/text-input'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'

const UpsertVersionDialog = ({
	language,
	scenarioId,
	data,
	toast,
	onClose,
	upserting,
	upsert
}) => {
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')

	const onUpsert = useCallback(() => {
		if (name.length < 3) {
			toast(LITERALS.PLEASE_ENTER_NAME_2[language], { type: 'error' })
			return
		}

		upsert(scenarioId, { name, description, data })
	}, [data, description, language, name, scenarioId, toast, upsert])

	return (
		<Dialog open={true} maxWidth='md' fullWidth={true}>
			<DialogTitle sx={styles.title}>
				<Typography variant='title' fontWeight='bold'>
					{LITERALS.VERSION_SAVE[language]}
				</Typography>
				<IconButton aria-label='close' onClick={onClose} sx={styles.closeIcon}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box sx={styles.box}>
					<Box sx={styles.formContainer}>
						<Box sx={styles.w100}>
							<TextInput
								label={LITERALS.NAME_2[language]}
								placeholder={LITERALS.PLEASE_ENTER_NAME_2[language]}
								value={name}
								onChange={e => { setName(e.target.value) }}
							/>
						</Box>
					</Box>
					<Box sx={styles.formContainer}>
						<Box sx={styles.w100}>
							<Typography variant='body2' color='text.primary' fontWeight='bold'>{LITERALS.DESCRIPTION[language]}</Typography>
							<TextArea rows={4} maxRows={10} value={description} onChange={e => { setDescription(e.target.value) }} />
						</Box>
					</Box>
				</Box>
				<CircularBackdrop open={upserting} position='absolute' />
			</DialogContent>
			<DialogActions sx={styles.p2}>
				<Button sx={styles.button} disabled={upserting} variant='contained' onClick={onUpsert}>{LITERALS.CREATE[language]}</Button>
			</DialogActions>
		</Dialog>
	)
}

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	box: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	w100: {
		width: '100%'
	},
	formContainer: {
		px: 3,
		mb: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	p2: {
		p: 2
	}
}

export default UpsertVersionDialog