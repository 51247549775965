import axios from 'axios'

import { logout } from './login'
import { connectSocketIO } from './socket'
import LITERALS from '../helpers/literals'

export const ACTION_TYPES = {
	FETCH_USERS_INIT: 'FETCH_USERS_INIT',
	FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
	FETCH_USERS_FAIL: 'FETCH_USERS_FAIL',
	GET_USER_INIT: 'GET_USER_INIT',
	GET_USER_SUCCESS: 'GET_USER_SUCCESS',
	GET_USER_FAIL: 'GET_USER_FAIL',
	CREATE_USER_INIT: 'CREATE_USER_INIT',
	CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
	CREATE_USER_FAIL: 'CREATE_USER_FAIL',
	UPDATE_USER_INIT: 'UPDATE_USER_INIT',
	UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
	UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',
	DELETE_USER_INIT: 'DELETE_USER_INIT',
	DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
	DELETE_USER_FAIL: 'DELETE_USER_FAIL',
	RESET_MESSAGES: 'RESET_MESSAGES'
}

export const fetch = historyPusher => {
	return async (dispatch) => {
		dispatch({ type: ACTION_TYPES.FETCH_USERS_INIT })
		try {
			const response = await axios({
				method: 'get',
				url: '/users',
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			if (response.data.err) {
				dispatch({ type: ACTION_TYPES.FETCH_USERS_FAIL, error: response.data.msg })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			} else {
				dispatch({ type: ACTION_TYPES.FETCH_USERS_SUCCESS, data: response.data.data })
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.FETCH_USERS_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const get = (id, historyPusher) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: ACTION_TYPES.GET_USER_INIT, id })
			const response = await axios({
				method: 'get',
				url: `/users/${id}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`
				}
			})
			dispatch({ type: ACTION_TYPES.GET_USER_SUCCESS, data: response.data })
			if (id === '-1' || response.data.id === getState().profile.id) {
				connectSocketIO(dispatch, {
					url: process.env.REACT_APP_MODE === 'production' ?
						`wss://${window.location.hostname}` : `http://${window.location.hostname}:${process.env.REACT_APP_SOCKET_PORT}`
				})
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.GET_USER_FAIL, id, error: err?.response?.data?.message || err.message })
		}
	}
}

export const create = (data, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.CREATE_USER_INIT })
		try {
			const response = await axios({
				method: 'post',
				url: `/users`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				data
			})

			if (response.data.err)
				dispatch({ type: ACTION_TYPES.CREATE_USER_FAIL, error: response.data.msg })
			else
				dispatch({
					type: ACTION_TYPES.CREATE_USER_SUCCESS,
					message: LITERALS.USER_CREATED[getState().profile.language],
					data: response.data
				})

			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.CREATE_USER_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const update = (data, historyPusher) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: ACTION_TYPES.UPDATE_USER_INIT })
			const response = await axios({
				method: 'put',
				url: `/users/${data.id}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`
				},
				data
			})

			if (response?.data?.userId === getState().profile.id) {
				localStorage.setItem('accessToken', response.data.token)
				localStorage.setItem('refreshToken', response.data.refreshToken)
				localStorage.setItem('tokenExpiresAt', response.data.expiresAt)
			}

			dispatch({
				type: ACTION_TYPES.UPDATE_USER_SUCCESS,
				data,
				message: LITERALS.USER_UPDATED[data.id === getState().profile.id ?
					(data.language || getState().profile.language) : getState().profile.language]
			})

			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (err?.response?.status === 401)
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.UPDATE_USER_FAIL, error: err?.response?.data?.message || err.message })
		}
	}
}

export const remove = (id, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.DELETE_USER_INIT, id })
		try {
			const response = await axios({
				method: 'delete',
				url: `/users/${id}`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			if (response.data.err) {
				dispatch({ type: ACTION_TYPES.DELETE_USER_FAIL, id, error: response.data.msg })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			} else {
				dispatch({ type: ACTION_TYPES.DELETE_USER_SUCCESS, id, message: LITERALS.USER_DELETED[getState().profile.language] })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.DELETE_USER_FAIL, id, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}