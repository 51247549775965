export const ACTION_TYPES = {
	CONNECT_SOCKET_IO: 'CONNECT_SOCKET_IO',
	DISCONNECT_SOCKET_IO: 'DISCONNECT_SOCKET_IO',
	SUBSCRIBE_TOPIC: 'SUBSCRIBE_TOPIC',
	UNSUBSCRIBE_TOPIC: 'UNSUBSCRIBE_TOPIC',
	LOG_TRIGGERED: 'LOG_TRIGGERED',
}

export const connectSocketIO = (dispatch, payload) => {
	dispatch({ type: ACTION_TYPES.CONNECT_SOCKET_IO, payload })
}

export const disconnectSocketIO = dispatch => {
	dispatch({ type: ACTION_TYPES.DISCONNECT_SOCKET_IO })
}