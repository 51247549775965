import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextButton from '../custom/text-button'
import MapperItem from '../custom/mapper-item'
import NoData from '../custom/no-data'
import AddButton from '../custom/add-button'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'

const MapperNodeSettings = ({ language, toast, node, update, cancel }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [sourceName, setSourceName] = useState('')
	const [destinationPath, setDestinationPath] = useState('')
	const [values, setValues] = useState([])
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setSourceName(node?.data?.sourceName || '')
			setDestinationPath(node?.data?.destinationPath || '')
			setValues(node?.data?.values || [])
			setId(node?.id)
		}
	}, [id, node])

	const add = () => {
		setValues(vals => [...vals, { sourceName: '', destinationPath: '' }])
	}

	const edit = (idx, key, value) => {
		setValues(vals => [...vals.map((v, i) => ({ ...v, [key]: i === idx ? value : v[key] }))])
	}

	const remove = idx => {
		setValues(vals => [...vals.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		if (!sourceName) {
			toast(LITERALS.PLEASE_ENTER_SOURCE_ARRAY[language], { type: 'error' })
			return
		}

		if (!destinationPath) {
			toast(LITERALS.PLEASE_ENTER_DESTINATION_ARRAY[language], { type: 'error' })
			return
		}

		for (const value of values) {
			if (!value.sourceName) {
				toast(LITERALS.PLEASE_ENTER_SOURCE_ELEMENT[language], { type: 'error' })
				return
			}

			if (!value.destinationPath) {
				toast(LITERALS.PLEASE_ENTER_DESTINATION_PATH[language], { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, sourceName, destinationPath, values } })
	}, [description, destinationPath, language, node, sourceName, toast, update, values])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.row}>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.SOURCE_ARRAY[language]}</Typography>
					<TextField fullWidth placeholder={`${LITERALS.EXAMPLE[language]}: source`} value={sourceName} onChange={e => setSourceName(e.target.value)} />
				</Box>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.DESTINATION_ARRAY[language]}</Typography>
					<TextField fullWidth placeholder={`${LITERALS.EXAMPLE[language]}: target`} value={destinationPath} onChange={e => setDestinationPath(e.target.value)} />
				</Box>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.VALUES[language]} active color={theme.palette.primary.mapperColor} onClick={() => { }} />
				</Box>
				<Box sx={styles.body}>
					{
						values.map((item, key) => (
							<MapperItem
								key={key}
								title={`${LITERALS.VALUE[language]} #${key + 1}`}
								divider={key !== 0}
								{...item}
								valueChanged={(k, v) => { edit(key, k, v) }}
								onDelete={() => { remove(key) }}
							/>
						))
					}

					{
						values.length === 0
							?
							<NoData
								language={language}
								color='mapperColor'
								title={LITERALS.NOTHING_HERE}
								subTitle={LITERALS.ADD_VALUE_SET}
								add={add}
							/>
							:
							<AddButton language={language} color='mapperColor' onClick={add} />
					}
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2
	},
	w50: {
		width: 'calc(50% - 8px)'
	},
	title: {
		my: 1.25
	},
	divider: {
		mt: 2,
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 330px)',
		pt: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		px: 2,
		py: 1
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1,
		mt: 2
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default MapperNodeSettings