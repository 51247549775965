import { connect } from 'react-redux'

import Layout from '../../components/layout'

const mapStateToProps = () => {
	return {}
}

const mapDispatchToProps = () => {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)