import { combineReducers } from 'redux'

import ai from './ai'
import profile from './profile'
import scenarios from './scenarios'
import users from './users'

const reducer = combineReducers({
    ai,
    profile,
    scenarios,
    users
})

export default reducer