import React from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { DeleteForeverRounded } from '@mui/icons-material'

import Dropdown from './dropdown'
import LITERALS from '../../helpers/literals'

const ParameterItem = ({ language, title, divider, name, value, params, valueChanged, onDelete }) => (
	<Box sx={styles.main}>
		{divider && <Box sx={styles.divider} />}
		<Typography fontWeight='bold' variant='h6'>{title}</Typography>

		<Box sx={styles.container}>
			<Box sx={styles.dropdown}>
				<Dropdown
					selected={name}
					placeholder={LITERALS.PARAMETER[language]}
					items={params.map(p => ({ id: p, name: p }))}
					onChange={it => valueChanged('name', it)}
				/>
			</Box>

			<Box sx={styles.field}>
				<TextField
					fullWidth
					placeholder={LITERALS.PARAMETER_QUESTION[language]}
					value={value}
					onChange={e => valueChanged('value', e.target.value)}
				/>
			</Box>

			<Box sx={styles.iconContainer} onClick={onDelete}>
				<DeleteForeverRounded sx={styles.icon} />
			</Box>
		</Box>
	</Box>
)

const styles = {
	main: {
		py: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		pt: 1.25,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	dropdown: {
		width: '30%'
	},
	field: {
		width: 'calc(70% - 60px)'
	},
	iconContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		mb: 2
	}
}

export default ParameterItem