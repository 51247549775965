import { ACTION_TYPES as ACTION_TYPES_LOGIN } from '../actions/login'
import { ACTION_TYPES as ACTION_TYPES_SCENARIOS } from '../actions/scenarios'
import { ACTION_TYPES as ACTION_TYPES_USERS } from '../actions/users'

const initialState = {
	id: '-1',
	name: '',
	username: '',
	language: 'ru',
	privileges: [],
	scenarios: [],
	logging: false,
	error: '',
	message: ''
}

const profile = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES_LOGIN.LOGIN_INIT:
			return {
				...state,
				error: '',
				logging: true
			}
		case ACTION_TYPES_LOGIN.LOGIN_SUCCESS:
			return {
				...state,
				id: action.id,
				logging: false
			}
		case ACTION_TYPES_LOGIN.LOGIN_FAILED:
			return {
				...state,
				error: action.error,
				logging: false
			}
		case ACTION_TYPES_LOGIN.LOGOUT:
			return {
				...state,
				id: '-1'
			}
		case ACTION_TYPES_USERS.GET_USER_SUCCESS:
			if (state.id !== '-1' && state.id !== action.data.id)
				return state

			return {
				...state,
				id: action.data.id,
				language: action.data.language,
				name: action.data.name,
				username: action.data.username,
				privileges: action.data.privileges,
				scenarios: action.data.scenarios,
			}
		case ACTION_TYPES_USERS.GET_USER_FAIL:
			if (state.id !== action.id) return state
			return {
				...state,
				error: action.error
			}
		case ACTION_TYPES_USERS.UPDATE_USER_SUCCESS:
			if (state.id !== action.data.id) return state
			return {
				...state,
				language: action.data.language,
				name: action.data.name,
				username: action.data.username,
				privileges: action.data.privileges,
				scenarios: action.data.scenarios,
			}
		case ACTION_TYPES_SCENARIOS.CREATE_SCENARIO_SUCCESS:
			return {
				...state,
				scenarios: [...state.scenarios, `${action.data.id}-e`]
			}
		default:
			return state
	}
}

export default profile