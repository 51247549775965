import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { logout } from './login'
import { ACTION_TYPES as ACTION_TYPES_SOCKET } from './socket'
import LITERALS from '../helpers/literals'

export const ACTION_TYPES = {
	FETCH_SCENARIOS_INIT: 'FETCH_SCENARIOS_INIT',
	FETCH_SCENARIOS_SUCCESS: 'FETCH_SCENARIOS_SUCCESS',
	FETCH_SCENARIOS_FAIL: 'FETCH_SCENARIOS_FAIL',
	GET_SCENARIO_INIT: 'GET_SCENARIO_INIT',
	GET_SCENARIO_SUCCESS: 'GET_SCENARIO_SUCCESS',
	GET_SCENARIO_FAIL: 'GET_SCENARIO_FAIL',
	CREATE_SCENARIO_INIT: 'CREATE_SCENARIO_INIT',
	CREATE_SCENARIO_SUCCESS: 'CREATE_SCENARIO_SUCCESS',
	CREATE_SCENARIO_FAIL: 'CREATE_SCENARIO_FAIL',
	UPDATE_SCENARIO_INIT: 'UPDATE_SCENARIO_INIT',
	UPDATE_SCENARIO_SUCCESS: 'UPDATE_SCENARIO_SUCCESS',
	UPDATE_SCENARIO_FAIL: 'UPDATE_SCENARIO_FAIL',
	DELETE_SCENARIO_INIT: 'DELETE_SCENARIO_INIT',
	DELETE_SCENARIO_SUCCESS: 'DELETE_SCENARIO_SUCCESS',
	DELETE_SCENARIO_FAIL: 'DELETE_SCENARIO_FAIL',
	GET_VERSIONS_INIT: 'GET_VERSIONS_INIT',
	GET_VERSIONS_SUCCESS: 'GET_VERSIONS_SUCCESS',
	GET_VERSIONS_FAIL: 'GET_VERSIONS_FAIL',
	GET_VERSION_INIT: 'GET_VERSION_INIT',
	GET_VERSION_SUCCESS: 'GET_VERSION_SUCCESS',
	GET_VERSION_FAIL: 'GET_VERSION_FAIL',
	UPSERT_VERSION_INIT: 'UPSERT_VERSION_INIT',
	UPSERT_VERSION_SUCCESS: 'UPSERT_VERSION_SUCCESS',
	UPSERT_VERSION_FAIL: 'UPSERT_VERSION_FAIL',
	DELETE_VERSION_INIT: 'DELETE_VERSION_INIT',
	DELETE_VERSION_SUCCESS: 'DELETE_VERSION_SUCCESS',
	DELETE_VERSION_FAIL: 'DELETE_VERSION_FAIL',
	TEST_SCENARIO_INIT: 'TEST_SCENARIO_INIT',
	TEST_SCENARIO_SUCCESS: 'TEST_SCENARIO_SUCCESS',
	TEST_SCENARIO_FAIL: 'TEST_SCENARIO_FAIL',
	RESET_TEST_HISTORY: 'RESET_TEST_HISTORY',
	RESET_MESSAGES: 'RESET_MESSAGES'
}

export const fetch = historyPusher => {
	return async (dispatch) => {
		dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_INIT })
		try {
			const response = await axios({
				method: 'get',
				url: '/scenarios',
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			if (response.data.err) {
				dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_FAIL, error: response.data.msg })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			} else {
				dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_SUCCESS, data: response.data.data })
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.FETCH_SCENARIOS_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const get = (id, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.GET_SCENARIO_INIT })
		try {
			const response = await axios({
				method: 'get',
				url: `/scenarios/${id}`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			if (response.data.err) {
				dispatch({ type: ACTION_TYPES.GET_SCENARIO_FAIL, error: response.data.msg })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			} else {
				const recipient = uuidv4()
				dispatch({ type: ACTION_TYPES_SOCKET.UNSUBSCRIBE_TOPIC, topic: getState().scenarios.recipient })
				dispatch({ type: ACTION_TYPES_SOCKET.SUBSCRIBE_TOPIC, topic: recipient })
				dispatch({ type: ACTION_TYPES.GET_SCENARIO_SUCCESS, data: response.data.data, recipient })
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.GET_SCENARIO_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const create = (data, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.CREATE_SCENARIO_INIT })
		try {
			const response = await axios({
				method: 'post',
				url: `/scenarios`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				data
			})

			if (response.data.err)
				dispatch({ type: ACTION_TYPES.CREATE_SCENARIO_FAIL, error: response.data.msg })
			else
				dispatch({
					type: ACTION_TYPES.CREATE_SCENARIO_SUCCESS,
					message: LITERALS.SCENARIO_CREATED[getState().profile.language],
					data: response.data
				})

			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.CREATE_SCENARIO_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const update = (data, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.UPDATE_SCENARIO_INIT, message: data.message })
		try {
			const response = await axios({
				method: 'put',
				url: `/scenarios/${data.id}`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				data
			})

			if (response.data.err) {
				dispatch({ type: ACTION_TYPES.UPDATE_SCENARIO_FAIL, error: response.data.msg })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			} else {
				delete data.data

				dispatch({
					type: ACTION_TYPES.UPDATE_SCENARIO_SUCCESS,
					message: LITERALS.SCENARIO_UPDATED[getState().profile.language],
					data,
					scenario: response?.data?.data || null
				})
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.UPDATE_SCENARIO_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const remove = (id, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.DELETE_SCENARIO_INIT, id })
		try {
			const response = await axios({
				method: 'delete',
				url: `/scenarios/${id}`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			if (response.data.err) {
				dispatch({ type: ACTION_TYPES.DELETE_SCENARIO_FAIL, id, error: response.data.msg })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			} else {
				dispatch({ type: ACTION_TYPES.DELETE_SCENARIO_SUCCESS, id, message: LITERALS.SCENARIO_DELETED[getState().profile.language] })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.DELETE_SCENARIO_FAIL, id, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const getVersions = (id, historyPusher) => {
	return async dispatch => {
		dispatch({ type: ACTION_TYPES.GET_VERSIONS_INIT })
		try {
			const response = await axios({
				method: 'get',
				url: `/scenarios/${id}/versions`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			dispatch({ type: ACTION_TYPES.GET_VERSIONS_SUCCESS, versions: response.data.data })
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.GET_VERSIONS_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const getVersion = (scenarioId, id, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.GET_VERSION_INIT })
		try {
			const response = await axios({
				method: 'get',
				url: `/scenarios/${scenarioId}/versions/${id}`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			const recipient = uuidv4()
			dispatch({ type: ACTION_TYPES_SOCKET.UNSUBSCRIBE_TOPIC, topic: getState().scenarios.recipient })
			dispatch({ type: ACTION_TYPES_SOCKET.SUBSCRIBE_TOPIC, topic: recipient })
			dispatch({ type: ACTION_TYPES.GET_VERSION_SUCCESS, data: response.data.data.scenarios, recipient })
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.GET_VERSION_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const upsertVersion = (id, data, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.UPSERT_VERSION_INIT })
		try {
			const response = await axios({
				method: 'post',
				url: `/scenarios/${id}/versions`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				data
			})

			if (response.data.err) {
				dispatch({ type: ACTION_TYPES.UPSERT_VERSION_FAIL, error: response.data.msg })
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			} else {
				dispatch({
					type: ACTION_TYPES.UPSERT_VERSION_SUCCESS,
					message: LITERALS.SCENARIO_VERSION_CREATED[getState().profile.language],
					version: response.data
				})
				setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
			}
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.UPSERT_VERSION_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const removeVersion = (scenarioId, id, historyPusher) => {
	return async (dispatch, getState) => {
		dispatch({ type: ACTION_TYPES.DELETE_VERSION_INIT })
		try {
			await axios({
				method: 'delete',
				url: `/scenarios/${scenarioId}/versions/${id}`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
			})

			dispatch({ type: ACTION_TYPES.DELETE_VERSION_SUCCESS, id, message: LITERALS.VERSION_DELETED[getState().profile.language] })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.DELETE_VERSION_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const test = (data, historyPusher) => {
	return async (dispatch) => {
		dispatch({ type: ACTION_TYPES.TEST_SCENARIO_INIT, message: data.message })
		try {
			const response = await axios({
				method: 'post',
				url: `/scenarios/test`,
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				data,
			})

			if (response.data.err)
				dispatch({ type: ACTION_TYPES.TEST_SCENARIO_FAIL, error: response.data.msg })
			else
				dispatch({ type: ACTION_TYPES.TEST_SCENARIO_SUCCESS })

			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		} catch (err) {
			if (!err.response || (err.response && (err.response.status === 401 || err.response.status === 403)))
				logout(historyPusher)

			dispatch({ type: ACTION_TYPES.TEST_SCENARIO_FAIL, error: err?.response?.data?.message || err.message })
			setTimeout(() => { dispatch({ type: ACTION_TYPES.RESET_MESSAGES }) }, 500)
		}
	}
}

export const reset = () => {
	return async (dispatch, getState) => {
		const recipient = uuidv4()
		dispatch({ type: ACTION_TYPES_SOCKET.UNSUBSCRIBE_TOPIC, topic: getState().scenarios.recipient })
		dispatch({ type: ACTION_TYPES_SOCKET.SUBSCRIBE_TOPIC, topic: recipient })
		dispatch({ type: ACTION_TYPES.RESET_TEST_HISTORY, recipient })
	}
}