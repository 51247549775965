import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Scenarios from '../../components/scenarios'
import { create, fetch, remove } from '../../actions/scenarios'

const mapStateToProps = state => {
	return {
		language: state.profile.language,
		privileges: state.profile.privileges,
		scenarioPrivileges: state.profile.scenarios,
		scenarios: state.scenarios.scenarios,
		fetching: state.scenarios.fetching,
		fetched: state.scenarios.fetched,
		updating: state.scenarios.updating,
		creating: state.scenarios.creating,
		lastCreated: state.scenarios.lastCreated,
		deleting: state.scenarios.deleting,
		message: state.scenarios.message,
		error: state.scenarios.error
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetch: () => dispatch(fetch(ownProps.history.push)),
		create: data => dispatch(create(data, ownProps.history.push)),
		remove: id => dispatch(remove(id, ownProps.history.push))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Scenarios))