import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'

import DialogTitle from '../custom/dialog-title'
import TextButton from '../custom/text-button'
import ConditionItem from '../custom/condition-item'
import AddButton from '../custom/add-button'
import { componentsInfo } from '../../helpers/constants'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'

const CheckConditionsNodeSettings = ({ language, toast, node, update, cancel, code, generating, generate, optimizedCode, checking, check }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [conditions, setConditions] = useState([])
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setConditions(node?.data?.conditions || [{ id: uuidv4(), name: '', value: '', isElse: true }])
			setId(node?.id)
		}
	}, [id, node])

	const add = () => {
		setConditions(cnds => {
			cnds.splice(cnds.length - 1, 0, { id: uuidv4(), name: '', value: '' })
			return [...cnds]
		})
	}

	const edit = (idx, key, value) => {
		setConditions(cnds => [...cnds.map((c, i) => ({ ...c, [key]: i === idx ? value : c[key] }))])
	}

	const remove = idx => {
		setConditions(cnds => [...cnds.filter((_, i) => i !== idx)])
	}

	const save = useCallback(() => {
		for (const condition of conditions) {
			if (condition.isElse) continue

			if (!condition.name) {
				toast(LITERALS.PLEASE_ENTER_CONDITION_TEXT[language], { type: 'error' })
				return
			}

			if (!condition.value) {
				toast(LITERALS.PLEASE_ENTER_CONDITION[language], { type: 'error' })
				return
			}
		}

		update({ ...node, data: { ...node.data, description, conditions } })
	}, [conditions, description, language, node, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.middle}>
				<Box sx={styles.items}>
					<TextButton title={LITERALS.CONDITIONS[language]} active color={theme.palette.primary.checkConditionsColor} onClick={() => { }} />
				</Box>
				<Box sx={styles.body}>
					{
						conditions.map((item, key) => (
							<ConditionItem
								key={key}
								language={language}
								title={`${LITERALS.CONDITION[language]} #${key + 1}`}
								divider={key !== 0}
								{...item}
								valueChanged={(k, v) => { edit(key, k, v) }}
								onDelete={() => { remove(key) }}
								type={node.type}
								code={code}
								generating={generating}
								generate={generate}
								optimizedCode={optimizedCode}
								checking={checking}
								check={check}
							/>
						))
					}

					<AddButton language={language} color='checkConditionsColor' onClick={add} />
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
		mb: 2
	},
	divider: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	middle: {
		width: '100%',
		height: 'calc(100% - 227px)',
		py: 2,
		display: 'flex',
		flexDirection: 'column'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		px: 1.5,
		overflowX: 'auto'
	},
	body: {
		width: '100%',
		height: 'calc(100% - 49px)',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		alignItems: 'center',
		px: 2,
		py: 1
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	}
}

export default CheckConditionsNodeSettings