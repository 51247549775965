import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import CircularBackdrop from '../custom/circular-backdrop'
import TextInput from '../custom/text-input'
import TextArea from '../custom/text-area'
import LITERALS from '../../helpers/literals'

const UpsertDialog = ({
	language,
	id,
	name,
	description,
	toast,
	onClose,
	updating,
	error,
	update
}) => {
	const [initialized, setInitialized] = useState(false)
	const [settings, setSettings] = useState({})
	const [stepError, setStepError] = useState('')

	const checkStep = useCallback(() => {
		if (settings?.name?.length < 3) {
			setStepError(LITERALS.PLEASE_ENTER_NAME_2[language])
			return
		}

		setStepError('')
	}, [language, settings])

	useEffect(() => {
		if (!initialized) {
			setSettings({ id, parentId: 1, name, description })
			setInitialized(true)
		}
		checkStep()
	}, [id, name, description, initialized, checkStep])

	useEffect(() => {
		if (!updating && error)
			toast(error, { type: 'error' })
	}, [error, toast, updating])

	return <Dialog open={true} maxWidth='md' fullWidth={true}>
		<DialogTitle sx={styles.title}>
			<Typography variant='title' fontWeight='bold'>
				{id === '-1' ? LITERALS.ADD_SCENARIO[language] : LITERALS.UPDATE_SCENARIO[language]}
			</Typography>
			<IconButton aria-label='close' onClick={onClose} sx={styles.closeIcon}>
				<Close />
			</IconButton>
		</DialogTitle>
		<DialogContent dividers>
			<Box sx={styles.box}>
				<Box sx={styles.formContainer}>
					<Box sx={styles.w100}>
						<TextInput
							label={LITERALS.SCENARIO_NAME[language]}
							placeholder={LITERALS.ENTER_SCENARIO_NAME[language]}
							value={settings.name || ''}
							onChange={e => { setSettings(s => ({ ...s, name: e.target.value })) }}
						/>
					</Box>
				</Box>
				<Box sx={styles.formContainer}>
					<Box sx={styles.w100}>
						<Typography variant='body2' color='text.primary' fontWeight='bold'>{LITERALS.SCENARIO_DESCRIPTION[language]}</Typography>
						<TextArea
							placeholder={LITERALS.ENTER_SCENARIO_DESCRIPTION[language]}
							rows={4}
							maxRows={10}
							value={settings.description || ''}
							onChange={e => { setSettings(s => ({ ...s, description: e.target.value })) }}
						/>
					</Box>
				</Box>
			</Box>

			<CircularBackdrop open={updating} position='absolute' />
		</DialogContent>
		<DialogActions sx={styles.p2}>
			<Tooltip title={stepError} arrow placement='left-start'>
				<span>
					<Button
						sx={styles.button}
						disabled={!!stepError || updating}
						variant='contained'
						onClick={() => { update(settings) }}
					>
						{settings.id !== '-1' ? LITERALS.UPDATE[language] : LITERALS.CREATE[language]}
					</Button>
				</span>
			</Tooltip>
		</DialogActions>
	</Dialog>
}

const styles = {
	title: {
		color: theme => theme.palette.primary.heading,
		m: 0,
		p: 2
	},
	closeIcon: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme => theme.palette.primary.heading
	},
	box: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	w100: {
		width: '100%'
	},
	formContainer: {
		px: 3,
		mb: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	p2: {
		p: 2
	}
}

export default UpsertDialog