import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Navbar from '../../components/layout/navbar'
import { logout } from '../../actions/login'
import { get, update } from '../../actions/users'
import { fetch } from '../../actions/scenarios'

const mapStateToProps = state => {
	return {
		userId: state.profile.id,
		language: state.profile.language,
		username: state.profile.username,
		name: state.profile.name,
		privileges: state.profile.privileges,
		scenarios: state.profile.scenarios,
		scenariosList: state.scenarios.scenarios,
		fetched: state.scenarios.fetched,
		userUpdating: state.users.updating,
		userMessage: state.users.message,
		userError: state.users.error
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchProfile: id => dispatch(get(id, ownProps.history.push)),
		fetchScenarios: () => dispatch(fetch(ownProps.history.push)),
		logout: () => dispatch(logout(ownProps.history.push)),
		updateUser: data => dispatch(update(data, ownProps.history.push))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))