import React from 'react'
import { Box, Link, Tooltip, Typography } from '@mui/material'
import { Help } from '@mui/icons-material'

import { useDnD } from './context'
import TooltipTitle from '../custom/tooltip-title'
import { components } from '../../helpers/constants'

const Navigation = ({ language }) => {
	const [, setType] = useDnD()

	const onDragStart = (evt, nodeType) => {
		setType(nodeType)
		evt.dataTransfer.effectAllowed = 'move'
	}

	return <Box sx={styles.main}>
		<Link href='/' sx={styles.link}>
			<img alt='Logo' src={`/static/images/intellectdialog.png`} style={styles.logo} />
		</Link>

		<Box sx={styles.container}>
			{
				components.map((item, key) => (
					<Box key={key}>
						<Box sx={styles.title}>
							<Typography variant='h4' fontWeight='bold' noWrap>{item.title[language]}</Typography>
						</Box>
						{
							item.items.map((it, idx) => (
								<Box sx={styles.item} key={idx} onDragStart={(event) => onDragStart(event, it.type)} draggable>
									<Box sx={styles.flex}>
										<it.Icon sx={{ ...styles.icon, color: it.color }} />
										<Typography variant='h6' color='text.secondary' noWrap fontWeight={600}>{it.title[language]}</Typography>
									</Box>
									<Tooltip title={<TooltipTitle title={it.help[language]} />} arrow>
										<Help sx={styles.helpIcon} />
									</Tooltip>
								</Box>
							))
						}
						{key < components.length - 1 && <Box sx={styles.divider}/>}
					</Box>
				))
			}
		</Box>
	</Box>
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start'
	},
	link: {
		height: 60,
		ml: 2
	},
	logo: {
		height: 60,
		width: 'auto'
	},
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		width: '100%',
		pl: 2,
		pr: 1,
		py: 2
	},
	item: {
		width: '100%',
		display: 'flex',
		padding: '11px 16px 11px 24px',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer'
	},
	icon: {
		fontSize: 16,
		mr: 1
	},
	helpIcon: {
		color: theme => theme.palette.primary.border,
		fontSize: 18,
		ml: 1
	},
	divider: {
		mx: 2,
		mt: 2,
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	flex: {
		display: 'flex'
	}
}

export default Navigation