import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, IconButton, Tooltip, TextField, Typography } from '@mui/material'
import { CheckRounded, JavascriptRounded } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import DialogTitle from '../custom/dialog-title'
import { componentsInfo } from '../../helpers/constants'
import TextArea from '../custom/text-area'
import TooltipTitle from '../custom/tooltip-title'
import GenerateCodeDialog from '../custom/generate-code-dialog'
import CheckCodeDialog from '../custom/check-code-dialog'
import TextButton from '../custom/text-button'
import LITERALS from '../../helpers/literals'

const FilterNodeSettings = ({ language, toast, node, update, cancel, code, generating, generate, optimizedCode, checking, check }) => {
	const [id, setId] = useState(null)
	const [description, setDescription] = useState('')
	const [sourceName, setSourceName] = useState('')
	const [destinationPath, setDestinationPath] = useState('')
	const [condition, setCondition] = useState('')
	const [showGenerateDialog, setShowGenerateDialog] = useState(false)
	const [showTestDialog, setShowTestDialog] = useState(false)
	const [instruction, setInstruction] = useState('')
	const [testCode, setTestCode] = useState('')
	const theme = useTheme()

	useEffect(() => {
		if (node?.id !== id) {
			setDescription(node?.data?.description || '')
			setSourceName(node?.data?.sourceName || '')
			setDestinationPath(node?.data?.destinationPath || '')
			setCondition(node?.data?.condition || '')
			setId(node?.id)
		}
	}, [id, node])

	const save = useCallback(() => {
		if (!sourceName) {
			toast(LITERALS.PLEASE_ENTER_SOURCE_ARRAY[language], { type: 'error' })
			return
		}

		if (!destinationPath) {
			toast(LITERALS.PLEASE_ENTER_DESTINATION_ARRAY[language], { type: 'error' })
			return
		}

		if (!condition) {
			toast(LITERALS.PLEASE_ENTER_FILTER_CONDITION[language], { type: 'error' })
			return
		}

		update({ ...node, data: { ...node.data, condition, description, sourceName, destinationPath } })
	}, [condition, description, destinationPath, language, node, sourceName, toast, update])

	return (
		<Box sx={styles.main}>
			<DialogTitle
				title={componentsInfo[node.type].title[language]}
				description={componentsInfo[node.type].description[language]}
				cancel={cancel}
			/>

			<Box sx={styles.row}>
				<TextArea
					placeholder={LITERALS.ENTER_COMPONENT_DESCRIPTION[language]}
					rows={3}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</Box>

			<Box sx={styles.divider} />

			<Box sx={styles.row}>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.SOURCE_ARRAY[language]}</Typography>
					<TextField fullWidth placeholder={`${LITERALS.EXAMPLE}: source`} value={sourceName} onChange={e => setSourceName(e.target.value)} />
				</Box>
				<Box sx={styles.w50}>
					<Typography fontWeight='bold' variant='h6' sx={styles.title} noWrap>{LITERALS.DESTINATION_ARRAY[language]}</Typography>
					<TextField fullWidth placeholder={`${LITERALS.EXAMPLE}: target`} value={destinationPath} onChange={e => setDestinationPath(e.target.value)} />
				</Box>
			</Box>

			<Box sx={styles.row}>
				<Box sx={styles.w100}>
					<Box sx={styles.items}>
						<TextButton title={LITERALS.FILTER_CONDITION[language]} active color={theme.palette.primary.chatAIColor1} onClick={() => { }} />
						<Box>
							<Tooltip title={<TooltipTitle title={LITERALS.GENERATE_CODE[language]} />} arrow>
								<Button variant='contained' size='small' color='primary' sx={styles.button2} onClick={() => { setShowGenerateDialog(true) }}>
									<JavascriptRounded sx={styles.icon} />
								</Button>
							</Tooltip>

							<Tooltip title={<TooltipTitle title={LITERALS.CHECK_CODE[language]} />} arrow>
								<IconButton color='success' onClick={() => { setTestCode(condition); setShowTestDialog(true) }}>
									<CheckRounded />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
					<TextArea
						rows={8}
						placeholder={`this.item.type === 'personal'`}
						value={condition}
						onChange={e => setCondition(e.target.value)}
					/>
				</Box>
			</Box>

			<Box sx={styles.buttonsContainer}>
				<Button variant='contained' color='primary' sx={styles.button} onClick={save}>{LITERALS.SAVE[language]}</Button>
			</Box>

			{showGenerateDialog && <GenerateCodeDialog
				language={language}
				type={node.type}
				instruction={instruction}
				setInstruction={setInstruction}
				code={code}
				generating={generating}
				cancel={() => { setShowGenerateDialog(false) }}
				generate={generate}
				apply={() => { setCondition(code); setShowGenerateDialog(false) }}
			/>}

			{showTestDialog && <CheckCodeDialog
				language={language}
				type={node.type}
				code={testCode}
				setCode={setTestCode}
				optimized={optimizedCode}
				checking={checking}
				cancel={() => { setShowTestDialog(false) }}
				check={check}
				apply={() => { setCondition(optimizedCode); setShowTestDialog(false) }}
			/>}
		</Box>
	)
}

const styles = {
	main: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2
	},
	w50: {
		mb: 1,
		width: 'calc(50% - 8px)'
	},
	w100: {
		width: '100%'
	},
	title: {
		my: 1.25
	},
	divider: {
		mt: 2,
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`
	},
	buttonsContainer: {
		width: '100%',
		borderTop: theme => `1px solid ${theme.palette.primary.divider}`,
		p: 1,
		mt: 2
	},
	button: {
		width: '100%',
		borderRadius: '100px'
	},
	items: {
		width: '100%',
		height: 49,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		pb: 1,
		overflowX: 'auto'
	},
	button2: {
		mr: 2,
		p: 0,
		borderRadius: 10
	},
	icon: {
		fontSize: 30
	}
}

export default FilterNodeSettings