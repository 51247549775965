import React, { useCallback, useEffect, useState } from 'react'
import { Box, Pagination, TextField, Tooltip, Typography } from '@mui/material'
import { ContentCopyRounded, DeleteForeverRounded, Widgets } from '@mui/icons-material'
import * as moment from 'moment'

import CircularBackdrop from '../custom/circular-backdrop'
import AddButton from '../custom/add-button'
import UpsertDialog from './upsert-dialog'
import DeleteDialog from '../custom/delete-dialog'
import TooltipTitle from '../custom/tooltip-title'
import LITERALS from '../../helpers/literals'

const COUNT = 20

const Scenarios = ({
	history,
	toast,
	language,
	privileges,
	scenarioPrivileges,
	scenarios,
	fetching,
	fetched,
	updating,
	creating,
	lastCreated,
	deleting,
	message,
	error,
	fetch,
	create,
	remove
}) => {
	const [page, setPage] = useState(1)
	const [allowed, setAllowed] = useState(false)
	const [scenario, setScenario] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [_scenarios, setScenarios] = useState([])
	const [deleteScenario, setDeleteScenario] = useState(null)

	useEffect(() => {
		if (!fetched && !fetching)
			fetch()
	}, [fetch, fetched, fetching])

	useEffect(() => {
		setAllowed(privileges.includes('scenarios-write'))
	}, [privileges])

	useEffect(() => {
		setScenarios([].concat(scenarios)
			.filter(s => scenarioPrivileges.includes(`${s.id}-v`) || scenarioPrivileges.includes(`${s.id}-e`))
			.filter(s => `${s.name} ${s.description}`.search(new RegExp(`${searchText}`, 'i')) > -1)
			.sort((a, b) => new Date(a.updatedAt || 0) < new Date(b.updatedAt || 0) ? 1 : -1))
	}, [scenarioPrivileges, scenarios, searchText])

	useEffect(() => {
		if (lastCreated)
			history.push(`/scenarios/${lastCreated}`)
	}, [history, lastCreated])

	useEffect(() => {
		if (message) {
			toast(message, { type: 'success' })
			if ([LITERALS.SCENARIO_CREATED[language], LITERALS.SCENARIO_UPDATED[language]].includes(message))
				setScenario(null)
			else if ([LITERALS.SCENARIO_DELETED[language]].includes(message))
				setDeleteScenario(null)
		}
	}, [language, message, toast])

	useEffect(() => {
		if (error)
			toast(error, { type: 'error' })
	}, [toast, error])

	const copyToClipboard = useCallback(async token => {
		await navigator.clipboard.writeText(token)
		toast(LITERALS.TOKEN_COPIED[language], { type: 'success' })
	}, [language, toast])

	return (
		<Box component='main' sx={styles.main}>
			<CircularBackdrop open={fetching || deleting.length > 0} position='absolute' />

			<Box sx={styles.titleBar}>
				<Typography variant='title' fontWeight='bold'>{LITERALS.SCENARIOS[language]}</Typography>
				<Box sx={styles.container}>
					<TextField sx={styles.search} placeholder={LITERALS.SEARCH[language]} value={searchText} onChange={e => setSearchText(e.target.value)} />
					{allowed && <AddButton style={styles.button} color='main' onClick={() => { setScenario({}) }} language={language} />}
				</Box>
			</Box>

			<Box sx={styles.tableContainer}>
				{
					_scenarios.length > 0
						?
						<>
							<Box sx={styles.table}>
								{
									_scenarios
										.filter((_, idx) => idx < page * COUNT && idx >= (page - 1) * COUNT)
										.map((scen, key) => (
											<Box sx={styles.scenarioRow} key={key}>
												<Box sx={styles.name}>
													<Typography fontWeight='bold' noWrap>{scen.name}</Typography>
													<Typography variant='body3' color='primary.disabled' noWrap>{scen.description || '---'}</Typography>
												</Box>
												<Typography variant='body2' sx={styles.updatedAt}>{moment(new Date(scen.updatedAt)).format('DD.MM.YYYY HH:mm')}</Typography>
												<Box sx={styles.buttons}>
													<Tooltip title={<TooltipTitle title={LITERALS.COPY_TOKEN[language]} />} arrow>
														<Box sx={styles.iconContainer} onClick={() => { copyToClipboard(scen.token) }}>
															<ContentCopyRounded sx={styles.copyIcon} />
														</Box>
													</Tooltip>

													<Tooltip title={<TooltipTitle title={LITERALS.GO_TO_SCENARIO[language]} />} arrow>
														<Box sx={styles.iconContainer} onClick={() => { history.push(`/scenarios/${scen.id}`) }}>
															<Widgets sx={styles.goIcon} />
														</Box>
													</Tooltip>

													{scenarioPrivileges.includes(`${scen.id}-e`) && (
														<Tooltip title={<TooltipTitle title={LITERALS.DELETE_SCENARIO[language]} />} arrow>
															<Box sx={styles.iconContainer} onClick={() => { setDeleteScenario(scen) }}>
																<DeleteForeverRounded sx={styles.deleteIcon} />
															</Box>
														</Tooltip>
													)}
												</Box>
											</Box>
										))
								}
							</Box>

							<Pagination count={Math.ceil(_scenarios.length / COUNT)} color='primary' size='medium' page={page} onChange={(_, value) => { setPage(value) }} />
						</>
						:
						<Typography sx={styles.text}>{LITERALS.SCENARIOS_NOT_FOUND[language]}</Typography>
				}
			</Box>

			{scenario && <UpsertDialog
				language={language}
				id='-1'
				name=''
				description=''
				toast={toast}
				onClose={() => { setScenario(null) }}
				updating={updating || creating}
				error={error}
				update={create}
			/>}

			{deleteScenario !== null && <DeleteDialog
				title={LITERALS.SCENARIO_DELETION[language]}
				question={`${LITERALS.DELETE_SCENARIO_QUESTION[language]} "${deleteScenario.name}"?`}
				yesText={LITERALS.YES[language]}
				cancel={() => { setDeleteScenario(null) }}
				deleting={deleting.includes(deleteScenario.id)}
				remove={() => { remove(deleteScenario.id) }}
			/>}
		</Box>
	)
}

const styles = {
	main: {
		alignItems: 'start',
		display: 'flex',
		flexGrow: 1,
		px: 8,
		flexDirection: 'column',
		minHeight: '100%'
	},
	titleBar: {
		mt: 10,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	search: {
		width: 200,
		mx: 3
	},
	button: {
		m: 0,
		height: 40
	},
	tableContainer: {
		width: '100%',
		my: 4,
		p: 3,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme => theme.palette.background.default,
		border: theme => `1px solid ${theme.palette.primary.headerBorder}`,
		borderRadius: '12px'
	},
	table: {
		width: '100%'
	},
	scenarioRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		mb: 2,
		px: 2.5,
		py: 1.5,
		border: theme => `1px solid ${theme.palette.primary.border}`,
		borderRadius: '10px'
	},
	name: {
		width: 'calc(100% - 332px)',
		display: 'flex',
		flexDirection: 'column'
	},
	updatedAt: {
		width: 150
	},
	buttons: {
		width: 150,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'end',
	},
	iconContainer: {
		ml: 1,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	copyIcon: {
		fontSize: 28,
		color: theme => theme.palette.primary.disabled
	},
	goIcon: {
		fontSize: 28
	},
	deleteIcon: {
		fontSize: 28,
		color: theme => theme.palette.error.main
	},
	text: {
		my: 8
	}
}

export default Scenarios