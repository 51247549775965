import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import NodeTitle from '../custom/node-title'
import NodeBody from '../custom/node-body'
import NodeButton from '../custom/node-button'
import LITERALS from '../../helpers/literals'

const ApiNode = ({ data }) => {
	const theme = useTheme()

	const styles = useCallback(() => {
		return {
			main: {
				width: 240,
				minHeight: 80,
				borderRadius: '16px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxShadow: `0px 8px 10px 0px ${theme.palette.primary.black}1A`,
				p: 1,
				opacity: data.focused ? 1 : 0.3,
				border: `2px solid ${data.logged ? theme.palette.success.main : `${theme.palette.primary.apiColor1}33`}`,
				background: data.logged
					?
					`${theme.palette.success.main}33`
					:
					`linear-gradient(180deg, ${theme.palette.primary.apiColor2} 0%, ${theme.palette.primary.apiColor3} 100%)`,
			},
			mainInError: {
				width: 240,
				minHeight: 80,
				borderRadius: '16px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				p: 1,
				opacity: data.focused ? 1 : 0.3,
				border: `2px solid ${theme.palette.primary.border}`,
				background: `${theme.palette.error.main}33`,
				animation: 'blink 1s',
				animationIterationCount: 'infinite',
				'@keyframes blink ': {
					'50%': {
						background: `${theme.palette.error.main}99`,
						borderColor: theme => theme.palette.error.main
					}
				}
			}
		}
	}, [data.focused, data.logged, theme])

	return <Box sx={data.inError ? styles().mainInError : styles().main}>
		<NodeTitle title={LITERALS.API_REQUEST[data.language]} color='primary.apiColor1' />
		{data.name && <NodeBody title={data.name} color={theme.palette.primary.apiColor1} />}
		<NodeButton
			innerColor={theme.palette.primary.contrastText}
			outerColor={theme.palette.primary.apiColor1}
		/>
	</Box>
}

export default ApiNode